import React from 'react'

import Hero from '@modules/hero/home-hero';
import Modules from '@modules';

export default function HomeTemplate({
  pageContext: {
    uri,
    seo,
    id,
    pageData = {
        frontPageLayout: {},
        modularLayout: {},
    },
  }
}) {

    const { modules = [], __typename } = pageData.modularLayout;
    const { hero = {} } = pageData.frontPageLayout;

    return (
        <main>
            
            <Hero
                module={hero}
            />

            <Modules 
                modules={modules}
                typeName={__typename}
                id={id}
            />      
        </main>
    )
}
