import styled, { css, keyframes } from 'styled-components';
import { theme } from '@theme';

const dotLoaderAnimation = keyframes`
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
`

export const StyledDotLoader = styled.div`
    opacity: 0;
    transition: .2s ease opacity;
    animation: .01s linear .25s 1 exitScreen forwards;

    ${props => props.$isLoading && css`
        opacity: 1;
        animation: unset;
    `}

    ${props => props.$hasOverlay && css`
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.85);
            z-index: 5;
        }
    `}

    ${props => !props.$isAbsolute && css`
        margin: 20px 0;

        ${theme.mediaQuerys.mdUp} {
            margin: 30px 0;
        }
    `}

    ${props => props.$isLarge && css`
        margin: 100px 0;

        ${theme.mediaQuerys.mdUp} {
            margin: 150px 0;
        }
    `}

    .loader {
        color: ${props => props.$color || 'white'};
        font-size: 10px;
        margin: 0 auto;
        z-index: 6;
        position: ${props => props.$isAbsolute ? 'absolute' : 'relative'};
        top: ${props => props.$isAbsolute ? '-5px' : '-15px'};
        left: ${props => props.$isAbsolute ? '50%' : '0'};
        transform: translate(-50%, -50%);
        text-indent: -9999em;
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;

        &::before, &::after, & {
            border-radius: 50%;
            ${props => props.$isLarge ? 
                css`
                    width: 2rem;
                    height: 2rem;
                `
            :
                css`
                    width: .75rem;
                    height: .75rem;
                `
            };      
            animation-fill-mode: both;
            animation: ${dotLoaderAnimation} 1.8s infinite ease-in-out;
        }

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
        }

        &::before {
            left: ${props => props.$isLarge ? '-3.5em' : '-2em'};
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;

            
        }

        &::after {
            left: ${props => props.$isLarge ? '3.5em' : '2em'};
            animation-delay: 0.18s;
        }
}
`

