import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

export const getModuleSpacing = ({spacer = 'margin', spacingSize = 'sm'}) => css`

    ${spacingSize === 'xs' && css`
        ${spacer}-top: 25px;
        ${spacer}-bottom: 25px;

        ${theme.mediaQuerys.mdUp} {
            ${spacer}-top: 30px;
            ${spacer}-bottom: 30px;
        }
    `}

    ${spacingSize === 'sm' && css`
        ${spacer}-top: 20px;
        ${spacer}-bottom: 20px;

        ${theme.mediaQuerys.mdUp} {
            ${spacer}-top: 30px;
            ${spacer}-bottom: 30px;
        }

        ${theme.mediaQuerys.lgUp} {
            ${spacer}-top: 40px;
            ${spacer}-bottom: 40px;
        }
    `}

    ${spacingSize === 'md' && css`
        ${spacer}-top: 30px;
        ${spacer}-bottom: 30px;

        ${theme.mediaQuerys.mdUp} {
            ${spacer}-top: 40px;
            ${spacer}-bottom: 40px;
        }

        ${theme.mediaQuerys.lgUp} {
            ${spacer}-top: 50px;
            ${spacer}-bottom: 50px;
        }
    `}

    ${spacingSize === 'lg' && css`
        ${spacer}-top: 50px;
        ${spacer}-bottom: 50px;

        ${theme.mediaQuerys.mdUp} {
            ${spacer}-top: 60px;
            ${spacer}-bottom: 60px;
        }

        ${theme.mediaQuerys.lgUp} {
            ${spacer}-top: 80px;
            ${spacer}-bottom: 80px;
        }
    `}
`

export const getModuleSpacingOffset = ({ direction = 'bottom', spacingSize = 'sm' }) => css`
     ${spacingSize === 'xs' && css`
        margin-${direction}: -25px;

        ${theme.mediaQuerys.mdUp} {
            margin-${direction}: -30px;
        }
    `}

    ${spacingSize === 'sm' && css`
        margin-${direction}: -20px;

        ${theme.mediaQuerys.mdUp} {
            margin-${direction}: -30px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-${direction}: -40px;
        }
    `}

    ${spacingSize === 'md' && css`
        margin-${direction}: -30px;

        ${theme.mediaQuerys.mdUp} {
            margin-${direction}: -40px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-${direction}: -50px;
        }
    `}

    ${spacingSize === 'lg' && css`
        margin-${direction}: -50px;

        ${theme.mediaQuerys.mdUp} {
            margin-${direction}: -60px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-${direction}: -80px;
        }
    `}
`