import React from 'react'
import { StyledCookieConsent, StyledButton } from './styles'

import CookieConsent from 'react-cookie-consent'

import { theme } from '@theme';
import { P } from '@styles/global-components/_typography';

const CookieConsentBtn = ({ children, ...otherProps }) => (
    <StyledButton
        backgroundColor={theme.colors.lightBlue}
        textColor={theme.colors.black}
        shadowColor={theme.colors.teal}
        shadowSize={'sm'}
        radius={'md'}
        {...otherProps}
    >
        {children}
    </StyledButton>
)

export default function CookieConsentTracking() {

    return (
        <StyledCookieConsent>
            <CookieConsent
                disableStyles
                containerClasses="container outer-container cookie-consent-container"
                contentClasses="cookie-text-wrapper"
                location="bottom"
                buttonText="Accept and Close"
                ButtonComponent={CookieConsentBtn}
                disableButtonStyles={true}
                buttonWrapperClasses="cookie-consent-btn-wrapper-class"
            >
                <P className="cookie-text">
                    We use cookies to provide you with the best, most relevant experience and customize content. Using our website means you're ok with that.
                </P>
            </CookieConsent>
        </StyledCookieConsent>

    )
}
