import React from 'react'

// import GatsbyImage from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

export default function WrImage({ 
    imageFile, 
    alt, 
    useGatsbyImage = true, 
    ...otherProps 
}) {

    if(!imageFile) return <img alt={alt} {...otherProps} />;
    
    if(imageFile.childImageSharp && useGatsbyImage) return (
        <GatsbyImage
            alt={alt}
            image={imageFile.childImageSharp.gatsbyImageData}
            {...otherProps}
        />
    )

    if(imageFile.publicURL) return (
        <img src={imageFile.publicURL} alt={alt} {...otherProps} />
    )

    return null;
}
