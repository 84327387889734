import React from "react"
import { Link } from "gatsby"

const config = require("../../../../node-lib/config")

const checkFirstCharacterSafe = str => {
  if (typeof str !== "string") return null
  if (str.length) {
    return str[0]
  }
}

const forceTrailingSlash = (str) => {
  if(!str) return ''; // direct it back to homepage

  if(str.includes('?')) return str; // don't force slash on urls with query param
  if(str[str.length - 1] !== '/') return str + '/';

  return str;
}

const mapInternalLinks = (str) => {
  return str.replace(config.BASE_URL, "").replace(config.FRONT_END_BASE_URL, "");
}

const formatUrl = (url) => {
  url = mapInternalLinks(url);
  return forceTrailingSlash(url);
}

export default function WrLink({ url = "", href= "", to = "", activeClassName = '',  children, ...otherProps }) {
  const formattedUrl = formatUrl(href);
  const formattedTo = forceTrailingSlash(to);

  if (to.length || checkFirstCharacterSafe(formattedUrl) === "/") {
    return (// using "a" tag for disabled client side routing
      <Link 
        to={formattedTo || formattedUrl} 
        activeClassName={activeClassName} 
        {...otherProps}
      > 
        {children}
      </Link>
    )
  }

  return (
    <a 
      href={href} 
      rel="noopener" // could add noreffer (blocks Refer  Header from being sent) as well??
      target="_blank" 
      {...otherProps}
    >
      {children}
    </a>
  )
}
