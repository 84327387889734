// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-about-index-jsx": () => import("./../../../src/templates/about/index.jsx" /* webpackChunkName: "component---src-templates-about-index-jsx" */),
  "component---src-templates-blog-landing-index-jsx": () => import("./../../../src/templates/blog-landing/index.jsx" /* webpackChunkName: "component---src-templates-blog-landing-index-jsx" */),
  "component---src-templates-blog-standard-index-jsx": () => import("./../../../src/templates/blog-standard/index.jsx" /* webpackChunkName: "component---src-templates-blog-standard-index-jsx" */),
  "component---src-templates-contact-index-jsx": () => import("./../../../src/templates/contact/index.jsx" /* webpackChunkName: "component---src-templates-contact-index-jsx" */),
  "component---src-templates-courses-index-jsx": () => import("./../../../src/templates/courses/index.jsx" /* webpackChunkName: "component---src-templates-courses-index-jsx" */),
  "component---src-templates-free-classes-index-jsx": () => import("./../../../src/templates/free-classes/index.jsx" /* webpackChunkName: "component---src-templates-free-classes-index-jsx" */),
  "component---src-templates-general-content-index-jsx": () => import("./../../../src/templates/general-content/index.jsx" /* webpackChunkName: "component---src-templates-general-content-index-jsx" */),
  "component---src-templates-home-index-jsx": () => import("./../../../src/templates/home/index.jsx" /* webpackChunkName: "component---src-templates-home-index-jsx" */),
  "component---src-templates-page-not-found-index-jsx": () => import("./../../../src/templates/page-not-found/index.jsx" /* webpackChunkName: "component---src-templates-page-not-found-index-jsx" */),
  "component---src-templates-results-and-reviews-index-jsx": () => import("./../../../src/templates/results-and-reviews/index.jsx" /* webpackChunkName: "component---src-templates-results-and-reviews-index-jsx" */)
}

