import React from 'react'

import PointerSvg from '@images/bg-graphics/wr-arrow.svg';

export default function WrPointer({ color = 'black', direction = 'left', className, ...otherProps }) {
    return <PointerSvg
        fill={color}
        className={`wr-pointer__module ${direction}${className ? ` ${className}` : ''}`}
        {...otherProps}
    />
}
