import React from 'react'

import Hero from '@modules/hero/about-hero';
import Modules from '@modules';

export default function AboutTemplate({
    pageContext: {
      uri,
      seo,
      id,
      pageData = {
        aboutLayout: {},
        modularLayout: {},
      },
    }
  }) {

    const { modules = [], __typename } = pageData.modularLayout;
    const { aboutHero = {} } = pageData.aboutLayout;

    return (
        <main>
            <Hero
                module={aboutHero}
            />

            <Modules 
                modules={modules}
                typeName={__typename}
                id={id}
            />   
        </main>
    )
}
