import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';
import { StyledModuleContainer } from '@styles/global-components/_containers';

export const StyledHeroContainer = styled.section`
    color: ${theme.colors.white};

    .hero-vertical-social-share {
        display: none;
    }

    .social-horizontal-seperator {
        width: 250px;
        max-width: 100%;
        margin: 0;

        ${theme.mediaQuerys.smUp} {
            width: 350px;
        }
    }

    .text-container {

        ${theme.mediaQuerys.mdUp} {
            flex: 1;
            padding-right: 7.5%;
        }

        ${theme.mediaQuerys.lgUp} {
            padding-right: 6.5%;
        }
    }

    .author-container {
        margin-bottom: 0;
        
        ${theme.mediaQuerys.mdUp} {
            justify-content: flex-start;
        }
    }

    .blog-hero-title {
        padding: 0;

        ${theme.mediaQuerys.mdUp} {
            text-align: left;
            margin-bottom: 20px;
        }
    }
`

export const StyledBreadcrumbs = styled.div`
    display: none;
    font-size: 1.8rem;
    opacity: .75;
    margin-bottom: 10px;

    ${theme.mediaQuerys.mdUp} {
        display: block;
    }

    .breadcrumb-item {
        text-decoration: none;
        margin-left: 5px;

        &.first {
            margin-left: 0;
        }
    }

    div {
        display: inline;
    }
`

export const StyledHeroTopBackground = styled(StyledModuleContainer)`
    padding: 0;
    margin: 0;
`

export const StyledHeroTopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 175px;
    padding-top: 25px;
    padding-bottom: 25px;

    ${theme.mediaQuerys.smUp} {
        min-height: 200px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    ${theme.mediaQuerys.mdUp} {
        align-items: center;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 40px;
        min-height: 250px;
    }

    ${theme.mediaQuerys.lgUp} {
        min-height: 265px;
    }
`

export const StyledHeroBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`

export const StyledFeaturedImage = styled(WrImage)`
    display: none;

    ${theme.mediaQuerys.mdUp} {
        display: block;
        border-radius: 50px;
        width: 32%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    ${theme.mediaQuerys.lgUp} {
    }

    img {
        border-radius: 50px;
    }
`