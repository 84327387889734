import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledButtonWrapper = styled.div`
    padding-bottom: 8px;
    width: ${props => props.$width || '100%'};

    ${props => props.$shadowSize === 'sm' && css`
        padding-bottom: 3px;
    `}
`

export const StyledButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* width: ${props => props.$width || '100%'}; */
    min-height: 53px;
    padding: 14px 12px;
    border-radius: 40px;
    border: 1px solid;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 800;
    text-align: center;
    font-family: ${theme.fonts.button};
    font-style: ${props => props.$fontStyle || ''};
    text-decoration: ${props => props.$textDecoration || ''};

    ${theme.mediaQuerys.smUp} {
        padding: 16px 14px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding: 18px 16px;
    }

    ${props => props.$disableFullWidth && css`

        ${theme.mediaQuerys.smUp} {
            width: auto;
            margin: 0 auto;
            padding-right: 45px;
            padding-left: 45px;
        }

        ${theme.mediaQuerys.mdUp} {
            padding-right: 55px;
            padding-left: 55px;
        }
    `};

    ${props => props.$buttonAlign === 'center' && css`
        margin-left: auto;
        margin-right: auto; 
    `}

    ${props => props.$buttonAlign === 'left' && css`
        margin-right: auto; 
    `}

    ${props => props.$buttonAlign === 'right' && css`
        margin-left: auto;
    `}

    ${props => props.$buttonOptions && props.$buttonOptions.enableButtonOptions ? css`
        background-color: ${props.$buttonOptions.buttonBackgroundColor || props.$backgroundColor};
        color: ${props.$buttonOptions.buttonTextColor || props.$textColor};   
    `:css`
        background-color: ${props.$backgroundColor || '#AF1E87'};
        color: ${props.$textColor || '#FFFFFF'};  
    `}

    ${props => props.$fontSize === 'sm' && css`
        font-size: 13px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 16px;
        }
    `}

    ${props => props.$fontSize === 'md' && css`
        font-size: 16px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 20px;
        }
    `}

    ${props => props.$fontSize === 'lg' && css`
        font-size: 20px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 23px;
        }
    `}

    ${props => props.$fontSize === 'xl' && css`
        font-size: 20px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 28px;
        }
    `}

    ${props => props.$fontSize === 'xxl' && css`
        font-size: 23px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 30px;
        }
    `}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: .25s ease opacity;
        border-radius: 40px;
        box-shadow: 10px 8px 8px;

        ${props => props.$buttonOptions && props.$buttonOptions.enableButtonOptions ? css`
            color: ${props.$buttonOptions.buttonShadowColor};
        `:css`
            color: ${props.$shadowColor || '#000000'};
        `}

        ${props => props.$shadowSize === 'sm' && css`
            box-shadow: 5px 4px 4px;
        `}
    }

    ${props => props.$radius === 'sm' && css`
        border-radius: 6px;

        &::before {
            border-radius: 6px;
        }
    `}

    ${props => props.$radius === 'md' && css`
        border-radius: 15px;

        &::before {
            border-radius: 15px;
        }
    `}

    &:hover::before {
        opacity: 0.4;
    }
`