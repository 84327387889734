import React from 'react'
import { StyledButtonsContainer, StyledTextWithBackground, StyledSubTitleTopAndImageContainer, StyledFeaturedImage, StyledTitle, StyledHero, StyledHeroContentContainer, StyledSubTitleBottom, StyledButton } from './styles'

import WrImage from '@commons/wr-image'

export default function CoursesHero({
    module,
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            innerSpacing={true}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
            data-name="hero_courses-hero"
        >
            <div className="container outer-container relative">
                <StyledHeroContentContainer>
                    {module.title && 
                        <StyledTitle
                            renderAs={'h1'}
                        > 
                            {module.title}
                        </StyledTitle>
                    }
      
                    <StyledSubTitleTopAndImageContainer>

                        {module.featuredImage &&
                            <StyledFeaturedImage>
                                <WrImage 
                                    useGatsbyImage={false}
                                    imageFile={module.featuredImage.imageFile} 
                                    alt={module.featuredImage.altText}
                                />
                            </StyledFeaturedImage>
                        }
                        
                    </StyledSubTitleTopAndImageContainer>

                    {module.textCta && 
                        <StyledTextWithBackground 
                            $backgroundColor={module.textCtaBackgroundColor || '#117782'}
                            $textColor={module.textCtaTextColor || 'white'}
                            dangerouslySetInnerHTML={{ __html: module.textCta }}
                        />
                    }
                   
                    {module.textBottom && 
                        <StyledSubTitleBottom
                            dangerouslySetInnerHTML={{ __html: module.textBottom }}
                        />
                    }
                    
                    <StyledButtonsContainer>

                        {module.buttonLink1 && 
                            <StyledButton 
                                href={module.buttonLink1.url} 
                                buttonOptions={module.buttonOptions}
                                buttonWrapperClass={'course-button'}
                            >
                                {module.buttonLink1.title}
                            </StyledButton>
                        }

                        {module.buttonLink2 && 
                            <StyledButton 
                                href={module.buttonLink2.url} 
                                buttonOptions={module.buttonOptions}
                                buttonWrapperClass={'course-button'}
                            >
                                {module.buttonLink2.title}
                            </StyledButton>
                        }

                    </StyledButtonsContainer>
                </StyledHeroContentContainer>
            </div>
        </StyledHero>
    )
}
