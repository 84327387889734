import React from 'react';
import { StyledButton, StyledButtonWrapper } from './styles';

import WrLink from '../wr-link';

export default function WrButton({ 
    children, 
    href, 
    className, 
    backgroundColor = '#AF1E87',
    shadowColor = '#000000',
    textColor = '#FFFFFF',
    textDecoration,
    fontSize = 'md', // options: sm, md (default), lg, xl
    radius = 'lg', // options: md, lg (default)
    text, // this prop is used for shortcodes
    fontStyle,
    width = '100%',
    buttonOptions,
    buttonAlign = '',
    buttonWrapperClass = '',
    buttonClass = '',
    shadowSize = 'md',
    disableFullWidth = false,
    ...otherProps 
}) {
    if(text || children) return (
        <StyledButtonWrapper
            $width={width}
            $shadowSize={shadowSize}
            className={buttonWrapperClass}
        >        
            <StyledButton 
                as={href ? WrLink : ''}
                href={href}
                className={className || buttonClass}
                $textDecoration={textDecoration}
                $fontSize={fontSize ? fontSize : 'md'}
                $fontStyle={fontStyle}
                $radius={radius}
                $disableFullWidth={disableFullWidth}
                $backgroundColor={backgroundColor}
                $textColor={textColor}
                $shadowColor={shadowColor}
                $shadowSize={shadowSize}
                $buttonAlign={buttonAlign}
                $buttonOptions={buttonOptions} // this comes from wordpress / acf on each module
                dangerouslySetInnerHTML={{ __html: children || text }}
                {...otherProps}
            />
        </StyledButtonWrapper>
    )

    return null;
}
