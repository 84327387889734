import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledModuleContainer } from '@styles/global-components/_containers';

import WysiwygContent from '@commons/wysiwyg-content';
import ModuleTitle from '@commons/module-title';
import WrImage from '@commons/wr-image';

const content_spacing = '30px'; // mobile first
const lg_text_offset = '40px';

const sm_content_width = '60%'; // excludes title
const md_content_width = '60%'; // includes title
const lg_content_width = '53%'; // includes title

const module_spacing = '30px';
const lg_module_spacing = '55px';

export const StyledHero = styled(StyledModuleContainer)`
    padding-top: ${module_spacing};
    padding-bottom: ${module_spacing};
    margin-bottom: 50px;

    ${theme.mediaQuerys.smUp} {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0;
    padding-bottom: 27px;
    padding-left: 20px;
    padding-right: 20px;

    ${theme.mediaQuerys.smUp} {
        padding-left: 0;
        padding-right: 0px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: ${sm_content_width};
        margin-left: auto;
    }

    ${theme.mediaQuerys.lgUp} {
        width: ${lg_content_width};
    }
`

export const StyledTitleIcon = styled(WrImage)`
    width: 30px;
    margin-left: 10px;

    ${theme.mediaQuerys.smUp} {
        width: 37px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 44px;
    }

    ${theme.mediaQuerys.lgUp} {
        width: 50px;
    }
`

export const StyledImageWithTextContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 ${content_spacing} 0;
`

export const StyledFeaturedImage = styled.div`
    width: calc(40% + 10px);
    margin-right: 10px;
    /* margin-left: -10px; */
    max-width: 200px;

    ${theme.mediaQuerys.xsUp} {
        width: calc(40% + 20px);
        margin-right: 15px;
        /* margin-left: -20px; */
    }

    ${theme.mediaQuerys.smUp} {
        position: absolute;
        width: 33%;
        max-width: 235px;
        left: 25px;
        bottom: 0;
        /* bottom: -${module_spacing}; */
    }
    ${theme.mediaQuerys.mdUp} {
        width: 34%;
        max-width: 380px;
        /* bottom: -${lg_module_spacing}; */
    }

    ${theme.mediaQuerys.lgUp} {
        width: 45%;
        max-width: 450px;
    }


    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const StyledSubTextTop = styled(WysiwygContent)`
    /* font-size: 1.6rem;
    line-height: 23px; */
    font-style: italic;
    flex: 1;

    p {
        margin: 0;
    }

    ${theme.mediaQuerys.smUp} {
        /* font-weight: 700;
        font-size: 1.8rem; */
        font-weight: 700;
        line-height: 25px;
        flex: unset;
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 2rem;
        line-height: 27px; */
        width: ${sm_content_width};
        margin-left: auto;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
        width: ${lg_content_width};
    }
`

export const StyledSubTextBottom = styled(WysiwygContent)`
    /* font-size: 1.6rem;
    line-height: 23px; */
    text-align: center;
    padding: 0 20px ${content_spacing} 20px;

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-left: auto;
        text-align: left;
        /* min-height: 260px; // should move this somewhere else?? used for featured image height */
        padding-left: 0;
        padding-right: 0;
        
    }

    ${theme.mediaQuerys.mdUp} {
        padding-bottom: 50px;
        /* font-size: 1.8rem;
        line-height: 25px; */
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
        width: ${lg_content_width};
    }

    /* p {
        margin: 0 0 ${content_spacing} 0;
    } */
`

export const StyledTextWithBackgroundContainer = styled.div`
    padding: 0 15px;
    position: relative;
    z-index: 0;
    margin-bottom: calc(-${module_spacing} - 10px);

    ${theme.mediaQuerys.smUp} {
        width: calc(${sm_content_width} + 40px);
        margin-right: -10px;
        margin-left: auto;
        margin-bottom: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        width: calc(${lg_content_width} + 80px);
        margin-right: -20px;
    }
`

export const StyledTextWithBackground = styled(ModuleTitle)`
    background-color: ${props => props.$backgroundColor || '#117782'};
    color: ${props => props.$textColor || '#117782'};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* background-color: ${props => props.backgroundColor || '#117782'}; */
    padding: 15px 15px;
    font-size: 1.6rem;
    line-height: 20px;
    font-weight: 800;
    font-style: italic;
    text-align: center;
    margin: 0 5px;
    min-height: 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${theme.mediaQuerys.smUp} {
        font-size: 2.2rem;
        line-height: 25px;
        padding: 5px 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* font-size: 4rem;
        line-height: 48px; */
    }

    /* p {
        margin: 0;
    } */
`

export const StyledStarIcon = styled.img`
    position: absolute;
    width: 70px;
    z-index: -1;

    ${theme.mediaQuerys.mdUp} {
        width: 100px;
    }

    ${props => props.isTopRight && css`
        top: -20px;
        right: -5px;
    `}

    ${props => props.isBottomLeft && css`
        bottom: -20px;
        left: -5px;
    `}
`