import styled, { css } from 'styled-components';
import { theme } from '@styles/theme.styles';
import { getModuleSpacing } from '../helpers/_module-spacing';

// TODO == rename props
// size = outer, standard, inner, (maybe blog??)
// => should 'inner' just have the max-width for blog????
// => need to confirm the inner container isn't used for any other reason
export const StyledContainer = styled.div`
    position: ${props => props.isRelative ? 'relative' : ''};
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 525px;

    ${theme.mediaQuerys.xsUp} {
        padding-left: 25px;
        padding-right: 25px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-left: 35px;
        padding-right: 35px;
    }

    ${props => props.type === 'inner' && css`
        max-width: 840px;
        padding-left: 20px;
        padding-right: 20px;

        ${theme.mediaQuerys.xsUp} {
            padding-left: 35px;
            padding-right: 35px;
        }
    `}

    ${props => props.type === 'text' && css`
        max-width: 1000px;
        padding-left: 20px;
        padding-right: 20px;

        ${theme.mediaQuerys.xsUp} {
            padding-left: 35px;
            padding-right: 35px;
        }
    `}


    ${props => props.type === 'standard' || !props.type ? css`
       
        ${theme.mediaQuerys.smUp} {
            max-width: 750px;
        }

        ${theme.mediaQuerys.mdUp} {
            max-width: 1050px;
        }

    `: null}

    ${props => props.type === 'outer' && css`
        max-width: 550px;
        ${theme.mediaQuerys.smUp} {
            max-width: 850px;
        }

        ${theme.mediaQuerys.mdUp} {
            max-width: 1200px;
        }
        
    `}
`

export const StyledModuleContainer = styled.section`
    overflow: ${props => props.isOverflowHidden ? 'hidden' : ''};
    position: relative;
    z-index: 0;
    background-size: ${props => !props.backgroundRepeat ? 'cover' : ''};
    background-position: center center;
    background-repeat: ${props => props.backgroundRepeat ? 'repeat' : ''};

    ${props => props.$sectionOptions && props.$sectionOptions.enableAdditionalOptions ? css`
        background-color: ${props.$sectionOptions.backgroundColor};
        background-image: url("${props.$sectionOptions.backgroundImage && props.$sectionOptions.backgroundImage.imageFile.publicURL}");
        color: ${props.$sectionOptions.textColor};
    `:css`
        color: ${theme.colors.black};
    `}

    ${props => (props.$sectionOptions && props.$sectionOptions.enableAdditionalOptions) || props.innerSpacing ? 
        getModuleSpacing({ spacer: 'padding', spacingSize: props.moduleSpacing || 'sm' })
    :
        getModuleSpacing({ spacer: 'margin', spacingSize: props.moduleSpacing || 'sm' })
    }
`