import React from 'react'
import { H1 } from '@styles/global-components/_typography'

import HtmlParser from '@commons/html-parser';

// using HtmlParser for --
// => special characters, shortcodes, etc..
export default function ModuleTitle({ 
    children, 
    renderAs = 'h2', // default title element
    titleFontOptions = {mobile: 'lg', desktop: 'sm'}, // default font sizing
    ...otherProps 
}) {
    return (
        <H1 
            as={renderAs} 
            titleFontOptions={titleFontOptions}
            {...otherProps}
        >
            <HtmlParser stripBlockTags={true}>
                {children}
            </HtmlParser>
        </H1>
    )
}
