
export const formatFilterablePostsDataObject = (posts) => {
    let postDataSeperatedByCategories = {
        'everything': posts
    };
    posts.forEach(post => {
        post.categories.nodes.forEach(({ name }) => {
            if(name !== 'Uncategorized') {
                name = name.toLowerCase();
                postDataSeperatedByCategories[name] ? 
                    postDataSeperatedByCategories[name].push(post) :
                    postDataSeperatedByCategories[name] = [post]
            }
            
        })
    })
    return postDataSeperatedByCategories;
}
