import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';

const content_spacing = '20px';
const md_content_spacing = '35px';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 800;
    margin: 0;
    margin-bottom: ${content_spacing};
    text-align: center;
    
    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
    }
`

export const StyledTableContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* flex-direction: column; */
    flex-wrap: wrap;

    ${theme.mediaQuerys.smUp} {
        padding-left: 30px;
        padding-right: 30px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-left: 50px;
        padding-right: 50px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-left: 40px;
        padding-right: 40px;
    }
`

export const StyledTableContent = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    margin-bottom: ${content_spacing};
    max-width: 300px;

    ${theme.mediaQuerys.smUp} {
        min-width: 210px;
        width: calc(100% / 3);
    }

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% / 3);
        max-width: 350px;
        margin-bottom: ${md_content_spacing};
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`

export const StyledTableIcon = styled(WrImage)`
    width: 175px;

    ${theme.mediaQuerys.smUp} {
        width: 150px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 200px;
    }

    /* ${theme.mediaQuerys.lgUp} {
        width: 225px;
    } */
`

const tableTextSizing = css`
    font-size: 1.4rem;
    line-height: 19px;

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
        line-height: 22px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.8rem;
        line-height: 24px;
        /* font-size: 2rem;
        line-height: 27px; */
    }
`

export const StyledTableTextHeader = styled.div`
    ${tableTextSizing}
    font-weight: 700;
    margin-top: 5px;

    ${theme.mediaQuerys.lgUp} {
        margin-top: 8px;
    }
`

export const StyledTableText = styled.div`
    ${tableTextSizing}

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.6rem;
        line-height: 22px;
    }
`