import React from 'react';
import { StyledBreadcrumbs, StyledFeaturedImage, StyledHeroBottomContainer, StyledHeroContainer, StyledHeroTopBackground, StyledHeroTopContainer} from './styles';

import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
} from 'react-share';

// blog hero -- common styles
import { StyledTitle, StyledAuthorContainer, StyledAuthorImage, StyledAuthorName } from '../styles';

import BlogSocialShare from '@commons/blog-social-share';
import WrLink from '@commons/wr-link';
import ShareCount from '../../share-count';

const slugToQueryParam = (str) => str.split('-').join('+');

export default function BlogStandardHero({
    module,
    title,
    categories,
    featuredImage,
    author,
    slug,
}) {
    const socials = ['facebook', 'twitter', 'pinterest']
    const shareUrl = "https://www.writingrevolt.com/how-to-set-up-a-freelance-writer-website/"
    return (
        <StyledHeroContainer>
            <StyledHeroTopBackground 
                $sectionOptions={module.sectionOptions}
                as={'div'}
                id={module.sectionOptions && module.sectionOptions.sectionId}
                data-name="hero_blog-standard"
            >
                <StyledHeroTopContainer className="container outer-container">

                    <div className="text-container">  
                        {categories && categories.length ? 
                            <StyledBreadcrumbs itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
                                <WrLink className="breadcrumb-item first h_text-underline" to="/blog" itemprop="url">
                                    <span itemprop="title">Blog</span>
                                </WrLink>
                                    {categories.map(({ isPrimary, node }) => {
                                        if(isPrimary && node.name !== 'Uncategorized') return (
                                            <div itemprop="child" itemscope itemtype="http://data-vocabulary.org/Breadcrumb"> ›
                                                <WrLink to={`/blog/?category=${slugToQueryParam(node.slug)}`} className="breadcrumb-item h_text-underline" href="http://www.example.com/books/authors" itemprop="url">
                                                    <span itemprop="title">{node.name}</span>
                                                </WrLink>
                                            </div>
                                        )
                                        return null;
                                    })}
                                
                            </StyledBreadcrumbs>  
                        : null}
                       
                        {title && 
                            <StyledTitle
                                className="blog-hero-title"
                                renderAs="h1"
                                titleFontOptions={{ mobile: 'lg', desktop: 'sm' }}
                            >
                                {title}
                            </StyledTitle>
                        } 
                        {author && 
                            <StyledAuthorContainer className="author-container">
                                <StyledAuthorImage 
                                    src={author.avatar.url} 
                                    alt={'Writing Revolt Blog Post Author Icon'} 
                                />
                                <StyledAuthorName>
                                    By {author.firstName || author.lastName ? 
                                        `${author.firstName} ${author.lastName}` :
                                        `Guest Author`
                                    }
                                    <ShareCount slug={slug} />       
                                </StyledAuthorName>
                            </StyledAuthorContainer>
                        }
                    </div>

                    {featuredImage && 
                        <StyledFeaturedImage 
                            imageFile={featuredImage.imageFile} 
                            alt={featuredImage.altText} 
                        />                    
                    }
                </StyledHeroTopContainer>         
                 
            </StyledHeroTopBackground>
            <StyledHeroBottomContainer className="inner-content-container">
                {socials && 
                    <>
                        <BlogSocialShare socials={socials} />
                        <hr className="social-horizontal-seperator" />
                    </>
                }
            </StyledHeroBottomContainer>
            
        </StyledHeroContainer>
    )
}
