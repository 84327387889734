import { createGlobalStyle } from 'styled-components'

// Vendor Imports
import { Normalize } from './vendor/_normalize';

// Global Style Imports
import { ThemeGlobals } from './global-style-classes/_theme-globals';
import { Fonts } from './global-style-classes/_fonts';
import { Spacers } from './global-style-classes/_spacers';
import { Animations } from './global-style-classes/_animations';
import { Helpers } from './global-style-classes/_helpers';
import { Containers } from './global-style-classes/_containers';

export const GlobalStyle = createGlobalStyle`
   
    /* Vendor Styles */
    ${Normalize}

    /* Global Styles */
    ${Fonts}
    ${ThemeGlobals}
    ${Containers}
    ${Spacers}
    ${Animations}
    ${Helpers}
`