import styled from 'styled-components';
import { theme } from '@theme';

export const StyledBlogSocialShare = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    color: ${theme.colors.white};
    font-weight: 700;
    font-size: px-to-rem(10px);
    padding-top: 20px;
    padding-bottom: 20px;

    .facebook {
        background-color: ${theme.colors.facebook};
        color: white;

        &:hover {
            background: white;
            color: ${theme.colors.facebook};

            .social-icon {
                fill: ${theme.colors.facebook};
            }
        }
    }

    .pinterest {
        background-color: ${theme.colors.pinterest};
        color: white;

        &:hover {
            background: white;
            color: ${theme.colors.pinterest};

            .social-icon {
                fill: ${theme.colors.pinterest};
            }
        }
    }

    .twitter {
        background-color: ${theme.colors.twitter};
        color: white;

        &:hover {
            background: white;
            color: ${theme.colors.twitter};

            .social-icon {
                fill: ${theme.colors.twitter};
            }
        }
    }

    .social-cta {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 20px;
        margin-right: 20px;
        text-decoration: none;
        border: 1px solid transparent;

        &, .social-icon {
            transition: .2s ease;
        }
        

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            border: 1px solid;
        }
    }

    .social-icon {
        display: block;
        height: 10px;
        width: auto;
        padding-right: 5px;
        fill: white;
    }

    &.vertical {
        display: none;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;
        background-color: white;
        color: black;
        width: auto;
        padding: 10px 5px;

       ${theme.mediaQuerys.mdUp} {
            display: flex;
            align-items: center;
        }

        .social-cta {
            display: block;
            margin: 2px 8px;
            padding: 6px;
            border-radius: 4px;
        }

        .share-total {
            margin-bottom: 5px;
        }

        .social-icon {
            height: 12px;
            padding: 0;
        }
    }
`