import React from 'react';
import './styles.css';

import Hero from '@blog/hero/blog-standard-hero';
import WysiwygContent from '@commons/wysiwyg-content';
import Modules from '@modules';
import BlogSidebar from '../../components/blog/blog-sidebar';

export default function BlogStandardTemplate({
    pageContext: {
        id,
        uri,
        pageData = {
            featuredImage: {},
            title: '',
            categories: {},
            author: {},
            blogStandardLayout: {},
            blogModularLayout: {},
        },
    }
  }) {
    const {
        featuredImage = {},
        title = '',
        categories = {}
    } = pageData;

    const {
        blogStandardHero,
        text
    } = pageData.blogStandardLayout;

    const {
        node: author
    } = pageData.author

    const {
        __typename,
        modules
    } = pageData.blogModularLayout

    return (
        <main className="blog-standard__template">
            <Hero 
                module={blogStandardHero}
                categories={categories.edges}
                featuredImage={featuredImage && featuredImage.node}
                title={title}
                slug={uri}
                author={author}
            />

            <div className="sidebar-and-content-wrapper">
                <BlogSidebar slug={uri} />

                <WysiwygContent 
                    className={'module-spacer-xs container blog-content-container blog-page'}
                    content={text}
                />
            </div>

        

            <Modules 
                modules={modules}
                typeName={__typename}
                id={id}
                isBlogPage={true}
            />  
        </main>
    )
}
