import styled, { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

import { getTitleFontSize, getBodyFontSize } from '../helpers/_font-sizing';

export const bodyFontSizing = css`
    ${props => props.bodyFontOptions ? 
        getBodyFontSize(props.bodyFontOptions)
    : 
        getBodyFontSize({})
    }
`

export const H1 = styled.h1`
    margin-top: 0;
    font-weight: 800;
    font-family: ${theme.fonts.header};

    ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({mobile: 'xl', desktop: 'md'})
    }
`

export const H2 = styled.h2`
    margin-top: 0;
    font-weight: 800;
    font-family: ${theme.fonts.header};

    ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({mobile: 'lg', desktop: 'sm'})
    }
`

export const H3 = styled.h3`
    margin-top: 0;
    font-weight: 800;
    font-family: ${theme.fonts.header};

     ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({mobile: 'lg', desktop: 'sm'})
    }
`

export const H4 = styled.h4`
    font-family: ${theme.fonts.header};

    ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({ mobile: 'md', desktop: 'xs' })
    }
`

export const H5 = styled.h5`
    ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({ mobile: 'md', desktop: 'xs' })
    }
`

export const H6 = styled.h6`
    ${props => props.titleFontOptions ? 
        getTitleFontSize(props.titleFontOptions)
    : 
        getTitleFontSize({ mobile: 'md', desktop: 'xs' })
    }
`

export const P = styled.p`
    ${bodyFontSizing};
`

export const Table = styled.table`
    ${bodyFontSizing};
`

export const Ul = styled.ul`
    padding-left: 18px;
    ${bodyFontSizing};
`

export const Ol = styled.ol`
    padding-left: 18px;
    ${bodyFontSizing};
`

export const Dl = styled.dl`
    ${bodyFontSizing};
`

export const Img = styled.img`
    width: auto;
    min-height: 30px;
    height: 1em;
    margin: 0 5px;

    ${props => !props.$useInlineImage && css`
        max-width: 100%;
        height: auto;
        margin: 0;

        ${theme.mediaQuerys.smUp} {
            max-width: 90%;
        }
    `}  
`
