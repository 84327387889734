import React from 'react'
import SearchBar from '@commons/search-bar'
import { StyledHeroContainer, StyledTitle, StyledText, StyledFeaturedImage, StyledBottomText } from './styles'

export default function PageNotFoundHero({
    module,
}) {

    return (
        <StyledHeroContainer className="container">
            {module.title && 
                <StyledTitle 
                    className="hero-spacer"
                    renderAs={'h1'}
                > 
                    {module.title}
                </StyledTitle>
            }

            {module.featuredImage &&
                <StyledFeaturedImage 
                    imageFile={module.featuredImage.imageFile}
                    useGatsbyImage={false}
                    alt={module.featuredImage.altText}
                    className="hero-spacer"
                />
            }
            {module.text && 
                <StyledText 
                    dangerouslySetInnerHTML={{ __html: module.text }}
                    className="hero-spacer"
                />
            }

            <SearchBar
                className="hero-spacer"
            />

            {module.bottomText && 
                <StyledBottomText 
                    dangerouslySetInnerHTML={{ __html: module.bottomText }}
                    className="hero-spacer"
                />
            }
        </StyledHeroContainer>
    )
}
