import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import ModuleTitle from '@commons/module-title';
import WrImage from '@commons/wr-image';

const content_spacing = '30px'; // mobile first
const lg_content_spacing = '40px'; // mobile first

const sm_content_width = '60%'; // excludes title
const md_content_width = '60%'; // includes title
const lg_content_width = '50%'; // includes title

const module_spacing = '30px';
const lg_module_spacing = '40px';

export const StyledHero = styled(StyledModuleContainer)`
    padding-top: 30px;
    padding-bottom: 30px;

    ${theme.mediaQuerys.smUp} {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledHeroContentContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        min-height: 400px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: ${md_content_width};
        min-height: 415px;
    }

    ${theme.mediaQuerys.lgUp} {
        width: ${lg_content_width};
        /* min-height: 530px; */
        min-height: 450px;
    }

`

export const StyledTitleAndImageContainer = styled.div`
    position: relative;

    ${theme.mediaQuerys.smUp} {
        position: static;
    }
`

export const StyledFeaturedImage = styled.div`
    position: absolute;
    /* right: -15px; */
    right: 5px;
    bottom: 0;
    width: 42%;
    max-width: 170px;

    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    ${theme.mediaQuerys.xsUp} {
        right: 5px;
    }

    ${theme.mediaQuerys.smUp} {
        width: 45%;
        max-width: 325px;
        right: 0;
        bottom: -${lg_module_spacing};
    }

    ${theme.mediaQuerys.mdUp} {
        max-width: 450px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* max-width: 620px; */
        max-width: 550px;
        width: 50%;
        bottom: -${lg_module_spacing};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-size: 1.8rem;
    line-height: 21px;
    font-weight: 900;
    margin: 0;
    padding-bottom: ${content_spacing};
    width: 62%;
    text-align: center;
    min-height: 160px;

    ${theme.mediaQuerys.xsUp} {
        font-size: 2rem;
        line-height: 23px;
    }

    ${theme.mediaQuerys.smUp} {
        text-align: left;
        font-size: 3rem;
        line-height: 36px;
        width: auto;
        min-height: auto;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 3.4rem;
        line-height: 39px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* font-size: 4rem; */
        font-size: 3.5rem;
        padding-bottom: 22px;
        /* line-height: 55px; */
        line-height: 45px;
    }
`

export const StyledSubTitle = styled.div`
    padding: 0 20px;
    margin: 0;

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-bottom: ${content_spacing};
        padding: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 30px;
    }

    p {
        font-size: 1.3rem;
        font-style: italic;
        text-align: center;
        margin: 0;

        ${theme.mediaQuerys.smUp} {
            text-align: left;
            font-size: 1.4rem;
        }

        ${theme.mediaQuerys.mdUp} {
            font-size: 1.6rem;
        }
    }
`

export const StyledInnerContentContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`

export const StyledButtonAndQuoteContainer = styled.div`
    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        padding-right: 40px;
    }
`

export const StyledButton = styled(WrButton)`
    margin-bottom: 25px;

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 18px;
    }
`

export const StyledQuoteContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 30px;
    margin-bottom: ${content_spacing};
    min-height: 90px;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 20px;
    }
`

export const StyledQuoteImage = styled(WrImage)`
    width: 65px;
    margin-right: 5px;

    ${theme.mediaQuerys.smUp} {
        margin-right: 10px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-right: 15px;
    }
`

export const StyledQuoteContent = styled.div`
    font-size: 1.3rem;
    margin: 0;

    ${theme.mediaQuerys.smUp} {
        font-size: 1.4rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
    }

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }
`

export const StyledPointer = styled(WrPointer)`
    display: block;
    position: absolute;
    top: 0;
    right: -15px;
    height: 105px;
    transform: rotate(-135deg) scaleX(-1);

    ${theme.mediaQuerys.xsUp} {
        transform: rotate(-125deg) scaleX(-1);
        right: -20px;
    }

    ${theme.mediaQuerys.smUp} {
        height: 90px;
    }
`