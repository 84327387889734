import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledHamburger = styled.button`
    position: relative;
    height: 25px;
    width: 40px;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;

    ${theme.mediaQuerys.mdUp} { 
        display: none;
    }

    &::before, &::after, .middle-line {
        display: block;
        position: absolute;
        left: 0; 
        width: 100%;
        height: 4px;
        background: white;
        /* transition: .2s ease-in-out; */
    }

    .middle-line {
        top: 50%;
        transform: translate(0, -50%);
    }

    &::before, &::after {
        content: ''; 
    }

    &::before {
        top: 0;
        transform: rotate(0deg) translate(0px, 0px);
    }

    &::after {
        bottom: 0;
        transform: rotate(0deg) translate(0px, 0px);
    }

    ${props => props.isToggledByClick && css`
        &::before, &::after, .middle-line {
            transition: .2s ease-in-out;
        }
    `}

    ${props => props.isOpen && css`

        &::before, &::after, .middle-line {
            transition: .2s ease-in-out;
        }

        .middle-line {
            transform: translate(100%, -50%);
            height: 2px;
        }

        &::before, &::after {
            height: 2px;
            width: 40px;
        }

        &::before {          
            transform: rotate(-45deg) translate(-3px, 11.5px);
        }

        &::after {
            transform: rotate(45deg) translate(-1px, -11.5px);
        }
    `}
`
