import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WysiwygContent from '@commons/wysiwyg-content';
import ModuleTitle from '@commons/module-title';

const content_spacing = '25px'; // mobile first
const md_content_spacing = '30px';
const lg_text_offset = '40px';

const sm_content_width = '50%'; // excludes title
const md_content_width = '55%'; // includes title
const lg_content_width = '53%'; // includes title

const module_spacing = '30px';
const lg_module_spacing = '55px';

export const StyledHero = styled(StyledModuleContainer)`
    padding-top: ${module_spacing};
    padding-bottom: ${module_spacing};

    ${theme.mediaQuerys.smUp} {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    /* font-size: 2.5rem;
    line-height: 34px; */
    font-size: 2rem;
    line-height: 28px;
    font-weight: 900;
    /* margin: 0; */
    margin: 0 0 ${content_spacing} 0;
    flex: 1;
    text-align:center;

    ${theme.mediaQuerys.smUp} {
        text-align: left;
        margin: 0;
        font-size: 2.8rem;
        line-height: 35px;
        /* font-size: 3.5rem;
        line-height: 43px; */
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 3.8rem;
        line-height: 47px; */
        font-size: 3.2rem;
        line-height: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* font-size: 4rem;
        line-height: 49px; */
        font-size: 3.5rem;
        line-height: 43px;
        width: ${lg_content_width};
    }
`

export const StyledImageWithTextContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 ${content_spacing} 0;

    ${theme.mediaQuerys.smUp} {
        flex-direction: row;
    }

    ${theme.mediaQuerys.mdUp} {
        margin: 0 0 ${md_content_spacing} auto;
        width: ${md_content_width};
    }

    ${theme.mediaQuerys.lgUp} {
        width: ${lg_content_width};
    }
`

export const StyledFeaturedImage = styled.div`
    width: calc(50% + 10px);
    /* margin-left: -10px; */
    max-width: 200px;
    /* padding-right: 10px; */

    ${theme.mediaQuerys.xsUp} {
        width: calc(50% + 20px);
        /* margin-left: -20px; */
    }

    ${theme.mediaQuerys.smUp} {
        position: absolute;
        width: 40%;
        /* max-width: 320px; */
        max-width: 255px;
        /* left: 25px; */
        left: 50px;
        bottom: 0;
    }
    ${theme.mediaQuerys.mdUp} {
        /* max-width: 425px; */
        max-width: 350px;
        width: 42.5%;
    }

    ${theme.mediaQuerys.lgUp} {
        width: 45%;
        /* max-width: 500px; */
        /* max-width: 425px; */
        max-width: 400px;
    }

    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const StyledSubTextTop = styled(WysiwygContent)`
    text-align: center;
    margin: 0 0 ${content_spacing} 0;

    ${theme.mediaQuerys.mdUp} {
        margin: 0 0 ${md_content_spacing} 0;
    }

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-left: auto;
        text-align: left;
        /* min-height: 260px; // should move this somewhere else?? used for featured image height */
        padding-left: 0;
        padding-right: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: ${md_content_width};
        margin-left: auto;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
        width: ${lg_content_width};
    }
`

export const StyledSubTextBottom = styled.div`
    display: none;

    ${theme.mediaQuerys.lgUp} {
        display: block;
        font-size: 1.6rem;
        line-height: 25px;
        margin-left: auto;
        text-align: center;
        margin-top: ${md_content_spacing};
        width: calc(${lg_content_width} + 200px);
        margin-right: -25px;
    }

   p {
       margin: 0 !important;
   }
`

export const StyledButtonWrapper = styled.div`
    position: relative;
    min-width: 260px;
    /* width: 80%; */
    width: calc(100% - 30px);
    max-width: 100%;
    /* margin: 0 auto; */
    margin-right: auto;
    padding-top: 5px;

    ${theme.mediaQuerys.smUp} {
        /* width: calc(${sm_content_width} - 40px);
        margin: 0 20px 0 auto; */
        width: calc(${sm_content_width} - 50px);
        margin: 0 50px 0 auto;
    }

    ${theme.mediaQuerys.mdUp} {
        /* width: calc(${md_content_width} - 200px);
        margin: 0 125px 0 auto; */
        width: calc(${md_content_width} - 150px);
        margin: 0 150px 0 auto;
    }

    ${theme.mediaQuerys.lgUp} {
        /* width: calc(${lg_content_width} - 250px); */
        padding-top: 10px;
        width: calc(${lg_content_width} - 175px);
        /* margin: 0 150px 0 auto; */
        margin: 0 175px 0 auto;
    }
`

export const StyledButton = styled(WrButton)`
    text-shadow: 5px 4px 4px #FFFFFF;
`

export const StyledPointer = styled(WrPointer)`
    position: absolute;
    /* top: -20px; */
    top: -45px;
    /* height: 50px; */
    height: 80px;

    ${theme.mediaQuerys.smUp} {
        top: -50px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* top: -25px; */
        top: -45px;
        /* height: 70px; */
        height: 100px;
    }

    ${props => props.$location === 'left' && css`
        left: -45px;
        transform: scaleX(-1);
        display: none;

        ${theme.mediaQuerys.mdUp} {
            left: -75px;
            transform: scaleX(-1) rotate(15deg);
        }
    `}

    ${props => props.$location === 'right' && css`
        /* right: -45px; */
        right: -75px;

        ${theme.mediaQuerys.smUp} {
            /* right: -75px; */
            /* height: 100px; */
            right: -80px;
            transform: rotate(0deg);
        }

        ${theme.mediaQuerys.mdUp} {
            /* right: -75px; */
            /* height: 100px; */
            right: -120px;
            transform: rotate(25deg);
        }

        ${theme.mediaQuerys.lgUp} {
            right: -100px;
            transform: rotate(15deg);
        }
    `}
`