import React, { useEffect, useState, useCallback } from 'react'
import { StyledSeperator } from './styles';

import Modules from '@modules';
import PaginationControls from './pagination-controls';
import useQueryParam from '@hooks/useQueryParam';
import useDidMountEffect from '@hooks/useDidMountEffect';
import { resetScrollPosition } from '@helpers/_reset-scroll-position';

const createNumberedArrayOfLength = (n) => {
    const array = []

    for (let i = 0; i < n; i++) {
        array.push(i + 1);     
    }

    return array;
}

export default function Pagination({ 
    id = 'pagination', 
    itemsPerPage = 4, 
    items = [],
    enableSeperators = false,
    disruptorModules = [],
    disruptorPosition = 1,
    disruptorTypename,
    disruptorId,
    location
}) {
    
    const [currentPageQuery, setCurrentPageQuery] = useQueryParam('page');
    const [activePage, setActivePage] = useState(1);

    const [pageNumbers, setPageNumbers] = useState([]);
    const [activeChildren, setActiveChildren] = useState(null);
    
    const onChildrenChange = useCallback((firstRender) => {
        let startingSliceIndex = 0;

        if(firstRender) startingSliceIndex = activePage === 1 ? 0 : activePage;
        if(!firstRender) setActivePage(1);

        const amountOfPages = Math.ceil(items.length / itemsPerPage);

        setPageNumbers(createNumberedArrayOfLength(amountOfPages))
        if (items.length) {
          setActiveChildren(
                items.slice(
                    startingSliceIndex,
                    itemsPerPage
                )
            )
        }
    }, [items]);

    useEffect(() => { // set active page to current page query on first render (if already in url)
        const intCurrentPageQuery = parseInt(currentPageQuery);
        if(intCurrentPageQuery > 1) setActivePage(intCurrentPageQuery);
    }, [])

    useDidMountEffect(() => { // keep active page & current page query param in sync (not on first render)\
        // const locationPageNumber = parseInt(new URLSearchParams(location.search).get("page"));

        if(activePage <= 1) setCurrentPageQuery('');
        else setCurrentPageQuery(`${activePage}`);

        // if(!locationPageNumber && currentPageQuery) setActivePage(1);
        // if(locationPageNumber && locationPageNumber != activePage) setActivePage(locationPageNumber);
    }, [activePage])

    useDidMountEffect(() => { // keep active page & current page query param in sync (not on first render)
        const locationPageNumber = parseInt(new URLSearchParams(location.search).get("page"));

        if(!locationPageNumber && currentPageQuery) setActivePage(1);
        else if(locationPageNumber && locationPageNumber != activePage) setActivePage(locationPageNumber);
    }, [location])
 
    useEffect(() => { // handle pagination / items to show per page
        resetScrollPosition();

        setActiveChildren(
            items.slice(
                activePage * itemsPerPage - itemsPerPage,
                itemsPerPage * activePage
            )
        )
    }, [activePage])

    useEffect(() => onChildrenChange(true), []) // init active children (check query params for page number)
    useDidMountEffect(onChildrenChange, [items]) // reset page if children change (reset for filtered children)


    if(activeChildren) return (
        <div>
            {activeChildren.map((activeChild, i) => {

                let activateDisruptor;
                if(!disruptorModules.length) activateDisruptor = false;
                else if(activeChildren.length <= disruptorPosition) activateDisruptor = true;
                else if(disruptorPosition === i) activateDisruptor = true;

                return (
                    <React.Fragment key={activeChild + id}>
                        {activeChild}

                        {activateDisruptor && 
                            <Modules
                                modules={disruptorModules}
                                typeName={disruptorTypename}
                                isDisruptor={true}
                                id={disruptorId}
                            />
                        }

                        {!activateDisruptor && enableSeperators ?
                            <StyledSeperator />
                        : null}
                    </React.Fragment>
                )
            })}

           <PaginationControls
                pageNumbers={pageNumbers}
                setActivePage={setActivePage}
                activePage={activePage}
                id={id}
           />
        </div>
    )
    return null;
}
