import React from 'react'
import loadable from '@loadable/component'
import CurrentYear from './current-year';

const WrButton = loadable(() =>  import('@commons/wr-button'));
const TextHighlight = loadable(() =>  import('./text-highlight'));
const TextFlag = loadable(() =>  import('./text-flag'));
const AffiliateDisclosure = loadable(() =>  import('./affiliate-disclosure'));
const TextBackground = loadable(() =>  import('./text-background'));

const toCamelCase = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };

// props are coming in this format prop_example="content"
// => want them to be camelCased to match current format propExample="content"
const turnPropsToCamelCase = (props = {}) => {
    if(!props) return null;

    const propsFormatted = {};

    for (const [key, value] of Object.entries(props)) {
        const formattedKey = toCamelCase(key);
        propsFormatted[formattedKey] = value;
    }
    
    return propsFormatted;
} 

const shortCodeTranslator = {
    'wr_button':  WrButton,
    'text_highlight': TextHighlight,
    'text_flag': TextFlag,
    'affiliate_disclosure': AffiliateDisclosure,
    'text_background': TextBackground,
    'current_year': CurrentYear
}

const getShortCode = (name) => shortCodeTranslator[name] ? shortCodeTranslator[name] : null;

// NOTE => All shortcodes that are only used via wordpress are in this 'shortcodes' folder
// => components that are used in both shortcodes and react are placed in '../commons'
export default function Start(props) {
    const { comp } = props;
    
    const formattedProps = turnPropsToCamelCase(props);
    const Component = getShortCode(comp);

    if(!Component) return null;
    return <Component {...formattedProps} />;
}
