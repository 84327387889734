import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

import { getModuleSpacing, getModuleSpacingOffset } from '../helpers/_module-spacing';

// TODO -- rework module spacer classes
export const Spacers = css`

    .module-spacer-xs {
        ${getModuleSpacing({spacer: 'margin', spacingSize: 'xs'})}
    }

    .module-spacer-inner-md {
        ${getModuleSpacing({spacer: 'padding', spacingSize: 'md'})}
    }

    .spacer-module-offset-bottom-md {
        ${getModuleSpacingOffset({ direction: 'bottom', spacingSize: 'md' })}
    }

    .blog-standard-overide-bottom-spacing {
        margin-bottom: 50px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 60px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-bottom: 70px;
        }
    }
`