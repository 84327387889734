import React from "react"
import { StyledDotLoader } from "./styles"

const DotLoader = ({ 
  hasOverlay = false, 
  isLoading = false, 
  // type = 'standard',
  isLarge = false,
  isAbsolute = false,
  color = 'white'
}) => (
  <StyledDotLoader 
    $hasOverlay={hasOverlay} 
    $isLoading={isLoading} 
    $isLarge={isLarge}
    $color={color}
    $isAbsolute={false}
  >
    {hasOverlay && <div className={"overlay"}></div>}
    <div className={`loader`}>Loading...</div>
  </StyledDotLoader>
)

export default DotLoader
