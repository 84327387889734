import React, { useState, useEffect } from 'react'

import { FaPinterest, FaFacebookF, FaTwitter } from 'react-icons/fa';
import useIsWindow from '@hooks/useIsWindow';

import ShareCount from '../share-count';
import WrButton from '@commons/wr-button';
import { StyledBlogSidebar } from './styles';
import { useScrollPosition } from '@hooks/useScrollPosition';

export default function BlogSidebar({ slug }) {

    const [isWindow] = useIsWindow();

    const [isVisible, setIsVisible] = useState(false);
    const [pageProgress, setPageProgress] = useState(0);

    useScrollPosition(({ currentPosition }) => {
      if(currentPosition.y < -450) setIsVisible(true);
      else setIsVisible(false);
    })

    return (
        <StyledBlogSidebar $isVisible={isVisible}>
            <div className="inner-sidebar-container">

                <div className="sidebar-social-container">
                    <div className="share-count-wrapper">
                        <ShareCount slug={slug} withSlash={false} />  
                    </div>

                    <a 
                        href={isWindow ?
                            `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`
                            : `javascript:void(0)`
                        }
                        className={`social-cta facebook`}
                        target="_blank"
                    >
                        <FaFacebookF className="social-icon" />
                        <span className="sr-only">
                            Facebook
                        </span>
                    </a>

                    <a 
                        href={isWindow ?
                            `http://twitter.com/share?text=${encodeURI(document.title)}&url=${window.location.href}&via=jordenmakelle`
                            : `javascript:void(0)`
                        }
                        className={`social-cta twitter`}
                        target="_blank"
                    >
                        <FaTwitter className="social-icon" />
                        <span className="sr-only">
                            Tweet
                        </span>
                    </a>

                </div>
               
                <p className="blog-sidebar-copy"> 
                    Do you want to learn how to make your first $1,000 freelance writing?
                </p>

                <WrButton
                    backgroundColor={'#148692'}
                    textColor={'#F4F4F4'}
                    shadowColor={'#FFFFFF'}
                    shadowSize={'sm'}
                    href={'https://writingrevolt.lpages.co/3-steps-to-1k-reg/'}
                    buttonWrapperClass="blog-sidebar-btn"
                >
                    >> Yes, show me!
                </WrButton>
            </div>
        </StyledBlogSidebar>
    )
}
