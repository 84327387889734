import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrButton from '@commons/wr-button';

export const StyledCookieConsent = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,.95);
    background: rgba(0,0,0,.85);
    z-index: 10;

    .cookie-consent-container {
        padding-top: 12.5px;
        padding-bottom: 10px;

        ${theme.mediaQuerys.smUp} {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        ${theme.mediaQuerys.mdUp} {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .cookie-consent-btn-wrapper-class {
        min-width: 150px;
        width: 50%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0 auto;

        ${theme.mediaQuerys.smUp} {
            margin: 0 0 -3px 0;
            width: 150px;
            padding-left: 10px;
        }

        ${theme.mediaQuerys.mdUp} {
            padding-left: 15px;
            width: 150px;
        }

        ${theme.mediaQuerys.lgUp} {
            padding-left: 20px;
            width: 150px;
        }
    }

    // button wrapper
    .cookie-consent-btn-wrapper-class > div {
        
        > button {
            padding: 5px 5px 6px 5px;
        }
    }

    .cookie-text {
        text-align: center;
        font-size: 1.2rem;
        line-height: 17px;
        margin-top: 0px;
        margin-bottom: 12.5px;
        color: ${theme.colors.white};

        ${theme.mediaQuerys.smUp} {
            margin-bottom: 0px;
            text-align: left;
        }
    }
`

export const StyledButton = styled(WrButton)`
    font-size: 1.1rem;
    min-height: 25px;
`