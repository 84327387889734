import React from 'react'
import { StyledSocialShare } from './styles';

import WrLink from '@commons/wr-link';

import { matchSocialContent } from './_helpers';

export default function SocialShare({ socials = [] }) {
    return (
        <StyledSocialShare>
            {socials.map(({ icon, link }, i) => {
                const socialContent = matchSocialContent(icon);
                if(!socialContent) return null;

                if(link) return (
                    <WrLink 
                        key={link.url + i + 'social-share'}
                        href={link.url} 
                        className={`social-icon-container ${icon}`}
                    >
                        <span className="sr-only">
                            Go to the Writing Revolt ${icon} page
                        </span>
                        <span className="social-icon">
                            {socialContent.icon}
                        </span>
                        
                    </WrLink>
                )
                return null;
            })}
        </StyledSocialShare>
    )
}
