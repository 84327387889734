import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

import Montserrat900Woff2 from '../fonts/montserrat-v15-latin-900.woff2';
import Montserrat900ItalicWoff2 from '../fonts/montserrat-v15-latin-900italic.woff2';
import Montserrat800Woff2 from '../fonts/montserrat-v15-latin-800.woff2';
import Montserrat800ItalicWoff2 from '../fonts/montserrat-v15-latin-800italic.woff2';
import Montserrat700Woff2 from '../fonts/montserrat-v15-latin-700.woff2';
import Montserrat700ItalicWoff2 from '../fonts/montserrat-v15-latin-700italic.woff2';
import Montserrat400Woff2 from '../fonts/montserrat-v15-latin-regular.woff2';
import Montserrat400ItalicWoff2 from '../fonts/montserrat-v15-latin-italic.woff2';

import Montserrat900Woff from '../fonts/montserrat-v15-latin-900.woff';
import Montserrat900ItalicWoff from '../fonts/montserrat-v15-latin-900italic.woff';
import Montserrat800Woff from '../fonts/montserrat-v15-latin-800.woff';
import Montserrat800ItalicWoff from '../fonts/montserrat-v15-latin-800italic.woff';
import Montserrat700Woff from '../fonts/montserrat-v15-latin-700.woff';
import Montserrat700ItalicWoff from '../fonts/montserrat-v15-latin-700italic.woff';
import Montserrat400Woff from '../fonts/montserrat-v15-latin-regular.woff';
import Montserrat400ItalicWoff from '../fonts/montserrat-v15-latin-italic.woff';

import Montserrat900Ttf from '../fonts/montserrat-v15-latin-900.ttf';
import Montserrat900ItalicTtf from '../fonts/montserrat-v15-latin-900italic.ttf';
import Montserrat800Ttf from '../fonts/montserrat-v15-latin-800.ttf';
import Montserrat800ItalicTtf from '../fonts/montserrat-v15-latin-800italic.ttf';
import Montserrat700Ttf from '../fonts/montserrat-v15-latin-700.ttf';
import Montserrat700ItalicTtf from '../fonts/montserrat-v15-latin-700italic.ttf';
import Montserrat400Ttf from '../fonts/montserrat-v15-latin-regular.ttf';
import Montserrat400ItalicTtf from '../fonts/montserrat-v15-latin-italic.ttf';

export const Fonts = css`

    /* montserrat-regular - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url('${Montserrat400Woff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),
            url('${Montserrat400Woff}') format('woff'), /* Modern Browsers */
            url('${Montserrat400Ttf}') format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }
    /* montserrat-italic - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 400;
        src: url('${Montserrat400ItalicWoff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),     
            url('${Montserrat400ItalicWoff}') format('woff'), /* Modern Browsers */
            url('${Montserrat400ItalicTtf}') format('truetype'); /* Safari, Android, iOS */
            font-display: fallback; 
    }

    /* montserrat-700 - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        src: url('${Montserrat700Woff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),
            url('${Montserrat700Woff}') format('woff'), /* Modern Browsers */
            url(${Montserrat700Ttf}) format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }
    /* montserrat-700-italic - latin */
        @font-face {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 700;
        src: url('${Montserrat700ItalicWoff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),
            url('${Montserrat700ItalicWoff}') format('woff'), /* Modern Browsers */
            url(${Montserrat700ItalicTtf}) format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }

    /* montserrat-800 - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        src: url('${Montserrat800Woff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),     
            url('${Montserrat800Woff}') format('woff'), /* Modern Browsers */
            url('${Montserrat800Ttf}') format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }
     /* montserrat-800-italic - latin */
     @font-face {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 800;
        src: url('${Montserrat800ItalicWoff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),     
            url('${Montserrat800ItalicWoff}') format('woff'), /* Modern Browsers */
            url('${Montserrat800ItalicTtf}') format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }

    /* montserrat-900 - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        src: url('${Montserrat900Woff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),            
            url('${Montserrat900Woff}') format('woff'), /* Modern Browsers */
            url('${Montserrat900Ttf}') format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }
    /* montserrat-900-italic - latin */
    @font-face {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 900;
        src: url('${Montserrat900ItalicWoff2}') format('woff2'); /* Super Modern Browsers */
        src: local(''),            
            url('${Montserrat900ItalicWoff}') format('woff'), /* Modern Browsers */
            url('${Montserrat900ItalicTtf}') format('truetype'); /* Safari, Android, iOS */
        font-display: fallback; 
    }
`