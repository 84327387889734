import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';

export const StyledHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    z-index: 10;
    width: 100%;
    background-color: ${theme.colors.navigationBackground};
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 75px;

    ${theme.mediaQuerys.mdUp} {
        min-height: 95px;
    }

    ${theme.mediaQuerys.lgUp} {
        min-height: 115px;
    }

    .header-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }    
`

export const StyledLogo = styled(WrImage)`
    position: relative;
    z-index: 1;
    height: 35px;
    width: auto;

    ${theme.mediaQuerys.mdUp} {
        height: 48px;
    }
`

export const StyledNav = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: ${theme.colors.navigationBackground};

    ${theme.mediaQuerys.mdUp} {
        justify-content: flex-end;
        position: relative;
        visibility: visible;
        opacity: 1;
        transition: none;
    }

    ${props => props.isToggledByClick && css`
        transition: .2s ease-in-out opacity, .2s linear visibility;
    `}

    ${props => props.isMobileNavOpen && css`
        visibility: visible;
        opacity: 1;
        transition: .2s ease-in-out opacity;
    `}
`

export const StyledNavigationList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-right: -2px;

    ${theme.mediaQuerys.mdUp} {
        flex-direction: row;
    }
`

export const StyledNavItem = styled.li`
    display: inline;
    position: relative;
    text-align: center; 
    font-size: 2.5rem;
    list-style-type: none;
    font-weight: 800;
    font-style: italic;
    margin-bottom: 25px;
    font-family: ${theme.fonts.header};
    overflow: hidden;
    padding: 5px 2px;

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
        margin-bottom: 0;
        margin-right: 39px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-right: 57px;
        font-size: 1.8rem;
    }

    a {
        text-decoration: none;
        color: ${theme.colors.white};
        transition: .2s color;
        

        &::after { 
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 5px);
            width: calc(100% + 10px);
            height: 3px;
            border-top: 3px solid ${theme.colors.navigationBackground};
            background-color: ${theme.colors.navigationTextActive};
            transition: .2s transform;
        }
    }

    &:last-of-type {
        margin-bottom: 0;

        ${theme.mediaQuerys.mdUp} {
            margin-right: 0;
        }
    }


    &:hover {

        a {
            color: ${theme.colors.navigationTextActive};

              
            &::after {
                transform: translate(-50%, 0px);
            }
        }
    }

    a.is-active {
        color: ${theme.colors.navigationTextActive};
      
        &::after {
            transform: translate(-50%, 0px);
        }
    }

    &.mobile-home-link {
        ${theme.mediaQuerys.mdUp} {
            display: none;
        }
    }
`