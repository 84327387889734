import React from 'react'
import { StyledButtonWrapper, StyledPointer, StyledButton, StyledImageWithTextContainer, StyledFeaturedImage, StyledHero, StyledSubTextBottom, StyledSubTextTop, StyledTitle } from './styles'

import WrImage from '@commons/wr-image'

export default function FreeClassesHero({
    module,
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            innerSpacing={true}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
            data-name="free-classes-hero"
        >
            <div className="container outer-container relative hero-content">
                <StyledImageWithTextContainer>
                    {module.featuredImage &&
                        <StyledFeaturedImage>
                            <WrImage 
                                useGatsbyImage={false}
                                imageFile={module.featuredImage.imageFile} 
                                alt={module.featuredImage.altText}
                            />
                        </StyledFeaturedImage>
                    }

                    {module.title && 
                        <StyledTitle
                            renderAs={'h1'}
                        > 
                            {module.title}
                        </StyledTitle>
                    }
                </StyledImageWithTextContainer>

                {module.textTop && 
                    <StyledSubTextTop 
                        bodyFontOptions={{ mobile: 'md', desktop: 'lg' }}
                        textSpacing={'md'}
                    >
                        {module.textTop}
                    </StyledSubTextTop>
                }
                
                {module.buttonLink && 
                    <StyledButtonWrapper>

                        <StyledButton 
                            href={module.buttonLink.url} 
                            buttonOptions={module.buttonOptions}
                            radius={'md'}
                            fontSize={'lg'}
                            fontStyle={'italic'}
                        >
                            {module.buttonLink.title}
                        </StyledButton>

                        {module.enablePointer &&
                            <StyledPointer 
                                color={module.pointerColor}
                                $location="right"
                            />
                        } 
                    </StyledButtonWrapper>
                }
            </div>
        </StyledHero>
    )
}
