import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledSeperator = styled.hr`
    width: 225px;      
    max-width: 500px;
    background: #C4C4C4;

    ${theme.mediaQuerys.smUp} {
        width: 70%;
    }

    &:last-of-type {
        display: none;
    }
`