import React, { useState, useEffect } from 'react'

import { useStaticQuery, graphql } from "gatsby"

import BlogHero from '@blog/hero/blog-landing-hero';
import Modules from '@modules';
import BlogListing from '@blog/blog-listing'
import { formatFilterablePostsDataObject } from './_helpers'

import useQueryParam from '@hooks/useQueryParam';
import useDidMountEffect from '@hooks/useDidMountEffect';


export default function BlogLandingTemplate({
    location,
    pageContext: {
      uri,
      seo,
      id,
      pageData = {
          modularLayout: {},
          disruptorLayout: {}
      },
    }
  }) {

    const { modules = [], __typename } = pageData.modularLayout;
    const { disruptorLayout } = pageData;

    // TODO - move this to gatsby node
    // => currently querying all post in two different locations :(
    const postsData = useStaticQuery(
        graphql`
          query {
            wpgraphql {
                categories {
                    nodes {
                        name
                    }
                }
                tags {
                    nodes {
                      name
                    }
                  }
                posts(first: 500) {
                    nodes {
                        uri 
                        title
                        featuredImage {
                            node {
                                mediaItemUrl
                                altText
                                imageFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData (
                                            layout: FULL_WIDTH
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP, AVIF]
                                        )
                                    }
                                }
                            }
                        } 
                        seo {
                            title
                            metaDesc
                        }
                        author {
                            node {
                                avatar {
                                    url
                                }
                                firstName
                                lastName
                            }
                        }
                        tags {
                            nodes {
                                name
                            }
                        }
                        categories {
                            nodes {
                                name
                            }
                        }
                    }
                }
            }
          }
        `
      )
    
    const { nodes: posts } = postsData.wpgraphql.posts;
    const { nodes: options } = postsData.wpgraphql.categories;

    const [activeOptionQuery, setActiveOptionQuery] = useQueryParam('category');
    const [activeOption, setActiveOption] = useState(activeOptionQuery || 'everything');

    const getOptions = (options) => options.filter(({name}) => name !== 'Uncategorized');
    const [filterablePostsData] = useState(formatFilterablePostsDataObject(posts))

    const [dropdownOptions, setDropdownOptions] = useState(getOptions(options))
    const [activePostsData, setActivePostsData] = useState(filterablePostsData[activeOption])

    useDidMountEffect(() => { // keep active option & active option query param in sync (not on first render)
        if(activeOption.toLowerCase() === 'everything' && activeOptionQuery) setActiveOptionQuery('');
        else setActiveOptionQuery(activeOption);
    }, [activeOption])

    useEffect(() => { // keep post data filtered to active option / category
        setActivePostsData(
            filterablePostsData[activeOption] ? filterablePostsData[activeOption] : []
        );
    }, [activeOption])


    const filterPostsData = (category = '') => {
        setActiveOption(category.toLowerCase());
    }

    return (
        <main className="blog-landing__template">
            
             <BlogHero 
                title={`I'm here to learn about`}
                options={[{name: 'everything'}, ...dropdownOptions]}
                activeOption={activeOption}
                filterPostsData={filterPostsData}
            />

            <BlogListing 
                disruptorLayout={disruptorLayout}
                activePosts={activePostsData} 
                location={location}
            />

            <Modules 
                modules={modules}
                typeName={__typename}
                id={id}
            />   
        </main>
    )
}

// ?page=1&category=freelance-writing