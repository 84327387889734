import React, { useState, useEffect } from 'react'
import { StyledPaginationButton, StyledPaginationButtons } from './styles';

const determineStartAndEndIndex = ({ activePage, maxPaginationNumbersShown, totalPages }) => {
        
    const distanceFromLastNumber = totalPages - activePage;

    let tempStartIndex = activePage;
    let tempEndIndex = activePage + maxPaginationNumbersShown;

    if(distanceFromLastNumber < maxPaginationNumbersShown)  {
        tempStartIndex = totalPages - maxPaginationNumbersShown + 1;
        tempEndIndex = totalPages + 1;
    }

    return {
        startIndex: tempStartIndex - 1,
        endIndex: tempEndIndex - 1
    }
}

export default function PaginationControls({ 
    pageNumbers = [], 
    setActivePage, 
    id, 
    activePage 
}) {

    const indexBreakpointNames = ['mobile', 'tablet', 'desktop'];

    const maxPaginationNumbersShown = {
        desktop: 10,
        tablet: 7,
        mobile: 2
    }

    const [startIndexMap, setStartIndexMap] = useState(null);
    const [endIndexMap, setEndIndexMap] = useState(null);

    useEffect(() => {
        const newStartIndexMap = {};
        const newEndIndexMap = {};

        indexBreakpointNames.map(breakpointName => {
            const { startIndex, endIndex } = determineStartAndEndIndex({
                activePage: activePage,
                maxPaginationNumbersShown: maxPaginationNumbersShown[breakpointName],
                totalPages: pageNumbers.length
            });

            newStartIndexMap[breakpointName] = startIndex;
            newEndIndexMap[breakpointName] = endIndex;
        })

        setStartIndexMap(newStartIndexMap);
        setEndIndexMap(newEndIndexMap);
    }, [activePage])

    if(pageNumbers && pageNumbers.length && startIndexMap && endIndexMap) return (
        <StyledPaginationButtons> 
            <StyledPaginationButton
                $isDisabled={activePage <= 1}
                className="prev"
                rel="prev"
                onClick={() => 
                    activePage > 1 ? 
                        setActivePage(activePage - 1):
                        null
                }
            >
                Previous
            </StyledPaginationButton>
           
           <div className="pagination-numbers-container">
                {/* <StyledPaginationButton
                    onClick={() => setActivePage(1)}
                    className="first"
                    $isDisabled={activePage <= 1}
                    key={id + 'first' + 'pagination-button'}
                >
                    1
                </StyledPaginationButton> */}

                {pageNumbers.map((pageNumber, i) => {
                    const isActiveOnMobile = startIndexMap.mobile <= i && i < endIndexMap.mobile; 
                    const isActiveOnTablet = startIndexMap.tablet <= i && i < endIndexMap.tablet; 
                    const isActiveOnDesktop = startIndexMap.desktop <= i && i < endIndexMap.desktop; 

                    return (
                        <StyledPaginationButton
                            onClick={() => setActivePage(i + 1)}
                            $isActiveOnMobile={isActiveOnMobile}
                            $isActiveOnTablet={isActiveOnTablet}
                            $isActiveOnDesktop={isActiveOnDesktop}
                            $isDisabled={activePage === pageNumber}
                            key={id + i + 'pagination-button'}
                        >
                            {pageNumber}
                        </StyledPaginationButton>
                    )
                })}

                {/* <StyledPaginationButton
                    onClick={() => setActivePage(pageNumbers.length)}
                    className="last"
                    $isDisabled={activePage === 1}
                    key={id + 'last' + 'pagination-button'}
                >
                    {pageNumbers.length}
                </StyledPaginationButton> */}
           </div>
            

            <StyledPaginationButton
                $isDisabled={activePage >= pageNumbers.length}
                className="next"
                rel="next"
                onClick={() => 
                    activePage < pageNumbers.length ?
                        setActivePage(activePage + 1):
                        null
                }
            >
                Next
            </StyledPaginationButton>
        </StyledPaginationButtons>
    )
    return null;
}