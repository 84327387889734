import React from 'react'
import { StyledTitleAndImageContainer, StyledFeaturedImage, StyledTitle, StyledButtonAndQuoteContainer, StyledQuoteContent, StyledHero, StyledHeroContentContainer, StyledInnerContentContainer, StyledQuoteContainer, StyledQuoteImage, StyledPointer, StyledSubTitle, StyledButton } from './styles'

import WrImage from '@commons/wr-image'

export default function HomeHero({
    module
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            innerSpacing={true}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
            data-name="home-hero"
        >
            <div className="container outer-container relative">
                <StyledHeroContentContainer className="hero-content">
                    <StyledTitleAndImageContainer>
                        {module.title && 
                            <StyledTitle
                                renderAs={'h1'}
                            > 
                                {module.title}
                            </StyledTitle>
                        }
                        
                        <StyledFeaturedImage>
                            <WrImage 
                                useGatsbyImage={false}
                                imageFile={module.featuredImage.imageFile} 
                                alt={module.featuredImage.altText}
                            />
                        </StyledFeaturedImage>
                    </StyledTitleAndImageContainer>
                    
                    <StyledInnerContentContainer>
                        <StyledButtonAndQuoteContainer>
                            <StyledButton 
                                href={module.buttonLink.url} 
                                buttonOptions={module.buttonOptions}
                                radius={'md'}
                            >
                                {module.buttonLink.title}
                            </StyledButton>
                            <StyledQuoteContainer>
                                <StyledQuoteImage
                                    critical
                                    loading="eager"
                                    alt={module.quoteImage.altText}
                                    imageFile={module.quoteImage.imageFile}
                                />
                                <StyledQuoteContent
                                    dangerouslySetInnerHTML={{ __html: module.quoteText }}
                                />
                                {module.enablePointer &&
                                    <StyledPointer 
                                        color={module.pointerColor}
                                    />
                                } 
                            </StyledQuoteContainer>
                            
                        </StyledButtonAndQuoteContainer>
                        <StyledSubTitle 
                            dangerouslySetInnerHTML={{ __html: module.text }}
                        />
                    </StyledInnerContentContainer>
                </StyledHeroContentContainer>
            </div>
        </StyledHero>
    )
}
