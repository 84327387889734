import React from "react";

import { Helmet } from "react-helmet";

const config = require("../../../node-lib/config");

function SEO({ isBlogPage = false, seo = {}, path = "/" }) {
  const {
    // isBlogPage, include this when post / blog support is added
    metaDesc = "",
    opengraphImage = null,
    opengraphTitle = "",
    title = "",
    twitterDescription = "",
    twitterImage = null,
    twitterTitle = "",
  } = seo

  return (
    <Helmet>
      <html lang="en" />
      <title>{opengraphTitle ? opengraphTitle : title}</title>
      <meta name="description" content={metaDesc} />
      <meta
        name="image"
        content={opengraphImage ? opengraphImage.mediaItemUrl : null}
      />

      {/* this is to block SEO -- REMOVE THIS FOR PRODUCTION */}
      {/* <meta name="robots" content="noindex, nofollow" /> */}

      <meta name="viewport" content="width=device-width, initial-scale=1" />
        
      {/* Schema org tags */}
      {/* <script>
        {JSON.stringify(schemaOrgJSON)}
      </script> */}

      {/* OpenGraph tags */}
      <meta property="og:url" content={config.FRONT_END_BASE_URL + path} />
      {isBlogPage ? <meta property="og:type" content="article" /> : null}
      <meta
        property="og:title"
        content={opengraphTitle ? opengraphTitle : title}
      />
      <meta property="og:description" content={metaDesc ? metaDesc : ""} />
      <meta
        property="og:image"
        content={opengraphImage ? opengraphImage.mediaItemUrl : ""}
      />

      {/* Twitter Card Tags */}
      {/* <meta 
        property="twitter:card"
        content={'summary_large_image'}
      /> */}
      <meta property="twitter:uri" content={config.FRONT_END_BASE_URL + path} />
      {/* <meta 
        property="twitter:creator"
        content={'squaqkvoices'}
      /> */}
      <meta
        property="twitter:title"
        content={
          twitterTitle ? twitterTitle : opengraphTitle ? opengraphTitle : title
        }
      />
      <meta
        property="twitter:description"
        content={
          twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
        }
      />
      <meta
        property="twitter:image"
        content={
          twitterImage
            ? twitterImage.mediaItemUrl
            : opengraphImage
            ? opengraphImage.mediaItemUrl
            : null
        }
      />
    </Helmet>
  )
}

export default SEO
