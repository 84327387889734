import React, { useState, useEffect } from 'react';
import { StyledHeader, StyledLogo, StyledNav, StyledNavigationList, StyledNavItem } from './styles';

import { useStaticQuery, graphql } from "gatsby"

import WrLink from '@commons/wr-link';
import Hamburger from './hamburger';

export default function Header({ location }) {

    const headerData = useStaticQuery(
        graphql`
          query {
            wpgraphql {
              header {
                headerLayout {
                  logo {
                    altText
                    mediaItemUrl

                    imageFile {
                        publicURL
                    }
                  }
                }
              }
              mainMenu: menuItems(where: { location: PRIMARY }, first: 500) {
                nodes {
                  title: label
                  path
                  parentId
                  key: id
                }
              }
            }
          }
        `
      )
    
    const { logo = "" } = headerData.wpgraphql.header.headerLayout
    const { nodes = [] } = headerData.wpgraphql.mainMenu

    const [hamburgerWasToggled, setHamburgerWasToggled] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const toggleMobileNav = () => {
      setHamburgerWasToggled(true);
      setIsMobileNavOpen(!isMobileNavOpen);
    }
    // const closeMobileNav = () => setIsMobileNavOpen(false);
    
    useEffect(() => {
        if(isMobileNavOpen) document.body.classList.add('mobile-nav-no-scroll');
        else document.body.classList.remove('mobile-nav-no-scroll');
    }, [isMobileNavOpen])

    useEffect(() => {
        setHamburgerWasToggled(false);
        setIsMobileNavOpen(false)
    }, [location])

    return (
        <StyledHeader>
            <div className="container outer-container header-content-container">
                <WrLink to="/">
                    <StyledLogo 
                        imageFile={logo.imageFile}
                        alt={logo.altText} 
                    />
                </WrLink>

                <Hamburger
                    onClick={toggleMobileNav}
                    isToggledByClick={hamburgerWasToggled}
                    isOpen={isMobileNavOpen}
                />

                <StyledNav 
                  isToggledByClick={hamburgerWasToggled}
                  isMobileNavOpen={isMobileNavOpen}
                >
                    <StyledNavigationList>
                        <StyledNavItem className="mobile-home-link">
                            <WrLink           
                                to={'/'}
                                activeClassName="is-active"
                                // className={location.pathname === '/' ? 'is-active' : ''}
                                // onClick={closeMobileNav}
                            >Home</WrLink>
                        </StyledNavItem>
                        {nodes && 
                            nodes.map(({ title, path, key }, i) => (
                                <StyledNavItem 
                                    $isMobileNavOpen={isMobileNavOpen}
                                    key={key + title + 'header-nav-item'}
                                >
                                    <WrLink   
                                      activeClassName="is-active"
                                        // className={location.pathname === path ? 'is-active' : ''}        
                                        to={path}
                                        activeClass
                                        // onClick={closeMobileNav}
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    />
                                </StyledNavItem>
                            ))
                        }
                   
                    </StyledNavigationList>
                </StyledNav>
            </div>
        </StyledHeader>
    )
}
