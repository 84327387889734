import React from 'react'
import loadable from '@loadable/component'

const CtaCardListing = loadable(() =>  import('@modules/cta-card-listing'));
const Video = loadable(() =>  import('@modules/video'));
const IconListing = loadable(() =>  import('@modules/icon-listing'));
const FeaturedContentWithImage = loadable(() =>  import('@modules/featured-content-with-image'));
const SignUpStepCta = loadable(() =>  import('@modules/sign-up-step-cta'));
const Gallery = loadable(() =>  import('@modules/gallery'));
const ContentArea = loadable(() =>  import('@modules/content-area'));
const LeadConversionCta = loadable(() =>  import('@modules/lead-conversion-cta'));
const Testimonials = loadable(() =>  import('@modules/testimonials'));
const BlogSectionHeader = loadable(() =>  import('@modules/blog-section-header'));
const ContentWithImage = loadable(() =>  import('@modules/content-with-image'));
const Quote = loadable(() =>  import('@modules/quote'));

const pageTypePrefixes = {
    "WPGraphQL_Page_Modularlayout": "page_",
    "WPGraphQL_Page_Disruptorlayout": "page_",
    "WPGraphQL_SiteOptions_Modularlayout": "SiteOptions_",
    "WPGraphQL_Post_Blogmodularlayout": "post_",
}


const getModule = (prefix, layout, moduleContainerName, fieldGroupName) => {

    const moduleTranslator = {
        [`${prefix}${layout}_${moduleContainerName}_CtaCardListing`]:  CtaCardListing,
        [`${prefix}${layout}_${moduleContainerName}_Video`]: Video,
        [`${prefix}${layout}_${moduleContainerName}_IconListing`]: IconListing,
        [`${prefix}${layout}_${moduleContainerName}_FeaturedContentWithImage`]: FeaturedContentWithImage,
        [`${prefix}${layout}_${moduleContainerName}_SignUpStepCta`]: SignUpStepCta,
        [`${prefix}${layout}_${moduleContainerName}_Gallery`]: Gallery,
        [`${prefix}${layout}_${moduleContainerName}_ContentArea`]: ContentArea,
        [`${prefix}${layout}_${moduleContainerName}_ContentAreaBlog`]: ContentArea,
        [`${prefix}${layout}_${moduleContainerName}_ContentWithImage`]: ContentWithImage,
        [`${prefix}${layout}_${moduleContainerName}_ContentWithImageBlog`]: ContentWithImage,
        [`${prefix}${layout}_${moduleContainerName}_LeadConversionCta`]: LeadConversionCta,
        [`${prefix}${layout}_${moduleContainerName}_Testimonials`]: Testimonials,
        [`${prefix}${layout}_${moduleContainerName}_BlogSectionHeader`]: BlogSectionHeader,
        [`${prefix}${layout}_${moduleContainerName}_Quote`]: Quote,
    }

    return moduleTranslator[fieldGroupName] ? moduleTranslator[fieldGroupName] : null;
}

export default function Start({
    modules, 
    typeName, 
    id, 
    isBlogPage = false,
    isDisruptor = false
}) {
    return (
        <>
            {modules.map((module, i) => {
                return (<LoadModule 
                    module={module} 
                    isBlogPage={isBlogPage}
                    typeName={typeName}
                    isDisruptor={isDisruptor}
                    key={id + i + "modular-layout"}
                />);
            })}
        </>
    )
}

const LoadModule = ({ 
    module, 
    typeName = "WPGraphQL_Page_Modularlayout", 
    isBlogPage,
    isDisruptor
 }) => {
    if(!module) return null;
    
    const prefix = pageTypePrefixes[typeName];
    const moduleContainerName = isDisruptor ? 'DisruptorModules' : 'Modules';

    let layout = 'Modularlayout';
    if(isBlogPage) layout = 'Blogmodularlayout';
    if(isDisruptor) layout = 'Disruptorlayout';

    const Module = getModule(prefix, layout, moduleContainerName, module.fieldGroupName);

    if(!Module) return null;
    return <Module module={module} isBlogPage={isBlogPage} />;
}