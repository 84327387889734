import styled, { css } from 'styled-components';
import { theme } from '@theme';

const cardContentSpacing = css`
    margin-bottom: 12px;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 17px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 22px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 25px;
    }
`

export const StyledBlogCard = styled.article`
    box-sizing: content-box;
    max-width: 600px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 0;
    padding-bottom: 0;

    ${theme.mediaQuerys.smUp} {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .featured-image {
        border-radius: 50px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        ${cardContentSpacing};

        img {
            border-radius: 50px;
        }
    }

    a {
        display: block;
        text-decoration: none;
        color: ${theme.colors.black};
    }
`

export const StyledTitle = styled.h2`
    margin-top: 0;
    font-size: 2.2rem;
    font-weight: 800;
    ${cardContentSpacing}

    ${theme.mediaQuerys.smUp} {
        font-size: 2.5rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 3rem;
    }
`

export const StyledBlogDetails = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-style: italic;
    ${cardContentSpacing}

    ${theme.mediaQuerys.smUp} {
        font-size: 1.4rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.8rem;
    }

    .author-image {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 12px;
        box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.25);

        @supports (filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))) {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            box-shadow: none;
        }

        ${theme.mediaQuerys.smUp} {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        ${theme.mediaQuerys.mdUp} {
            margin-right: 20px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-right: 25px;
        }
    }

    .author {
        margin-right: 7px;

        ${theme.mediaQuerys.mdUp} {
            margin-right: 10px;
        }
    }

    .total-comments {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 3px;
            background-color: black;
            border-radius: 50%;
            margin-right: 7px;

            ${theme.mediaQuerys.mdUp} {
                margin-right: 10px;
            }
        }
    }
`

export const StyledDescription = styled.p`
    font-size: 1.4rem;
    font-style: italic;
    margin: 0;

    ${theme.mediaQuerys.smUp} {
        font-size: 1.6rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.8rem;
    }
`

export const StyledButtonAndPointer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    ${theme.mediaQuerys.smUp} {
        margin-top: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-top: 35px;
    }

    .blog-card-pointer {
        width: 20px;
        position: absolute;
        top: -25px;
        left: -12px;
        transform: scaleX(-1);

        ${theme.mediaQuerys.xsUp} {
            width: 25px;
            top: -35px;
            left: -20px;
        }

        ${theme.mediaQuerys.smUp} {
            width: 50px;
            position: absolute;
            left: 13%;
            top: -30px;
            transform: scaleX(-1) rotate(20deg);
        }

        ${theme.mediaQuerys.lgUp} {
            width: 55px;
            top: -35px;
            left: 12%;
        }
    }

    .blog-card-btn-wrapper {

        ${theme.mediaQuerys.smUp} {
            width: 75%;
        }
    }

    .blog-card-btn {
        padding-top: 14px;
        padding-bottom: 14px;
        max-height: 52px;
    }
`