import styled from 'styled-components';
import { theme } from '@theme';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';

export const StyledHeroContainer = styled.section`
    box-sizing: content-box;
    max-width: 550px;
    text-align: center;
    padding-top: 35px;
    padding-bottom: 35px;

    ${theme.mediaQuerys.smUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .hero-spacer {
        margin-top: 25px;
        margin-bottom: 25px;

        ${theme.mediaQuerys.mdUp} {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
`

export const StyledTitle = styled(ModuleTitle)`
    margin-top: 0;
    font-size: 4.5rem;
    line-height: 45px;
    font-weight: 800;

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 25px;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
`

export const StyledText = styled.div`
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 27px;

    ${theme.mediaQuerys.mdUp} {
        font-size: 2.5rem;
        line-height: 30px;
    }

    p {
        margin: 0;
    }
`

export const StyledBottomText = styled.div`
    font-size: 1.8rem;
    font-style: italic;
    line-height: 24px;
    /* margin-bottom: 0 !important; */

    p {
        margin: 0;
    }
`