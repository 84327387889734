import React from 'react'
import { StyledContentContainer, StyledTextContentContainer, StyledHero, StyledTitle, StyledFeaturedImage, StyledSubText } from './styles'


export default function ContactHero({
    module,
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            isOverflowHidden={true}
        >
            <StyledContentContainer className="container outer-container">
            
                <StyledTextContentContainer>
                    {module.title && 
                        <StyledTitle
                            renderAs={'h1'}
                        > 
                            {module.title}
                        </StyledTitle>
                    }
                  

                    {module.text && 
                        <StyledSubText
                            dangerouslySetInnerHTML={{ __html: module.text }}
                        />
                    }
                 
                </StyledTextContentContainer>


                {module.featuredImage && 
                    <StyledFeaturedImage 
                        useGatsbyImage={false}
                        imageFile={module.featuredImage.imageFile} 
                        alt={module.featuredImage.altText}
                    />
                }
              
            </StyledContentContainer>
        </StyledHero>
    )
}
