import React, { useEffect } from 'react'
import { StyledSearchBarContainer, StyledSearchButton, StyledSearchInput } from './styles';

import { useLocation } from '@reach/router';
import { useState } from 'react'
import { navigate } from 'gatsby-link';

const config = require('@config');

export default function SearchBar({ className, ...otherProps }) {

    const location = useLocation();

    const [searchText, setSearchText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => setSearchText(e.target.value);
    const handleKeyDown = (e) => e.key === 'Enter' ? handleSubmit() : null;
    const handleSubmit = () => {
        // if(window.location.pathname !== '/search-results/') window.location.href = `${window.location.origin}/search-results/?q=${searchText}`;
        // else navigate(`/search-results/?q=${searchText}`);
        navigate(`/search-results/?q=${searchText}`);
    }
    
    useEffect(() => {
        setSearchText('');
    }, [location])

    return (
        <StyledSearchBarContainer {...otherProps}>
            <span className="sr-only">Global Search Bar</span>
            <StyledSearchInput
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                disabled={isSubmitting ? 'disabled' : ''}
                value={searchText}
                type="search" 
                name="" 
                id=""
            />
            <StyledSearchButton
                isSubmitting={isSubmitting}
                onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                Search
            </StyledSearchButton>
        </StyledSearchBarContainer>
    )
}
