
// http://writing-revolt.local 
// https://createboldly.net
// https://writingrevolt-production.com
const DEV_URL = 'http://writing-revolt.local';
const STAGING_URL = 'https://writingrevolt-staging.com';
const PRODUCTION_URL = 'https://writingrevolt-production.com';


const BASE_URL = process.env.NODE_ENV === 'development' ?
    `https://writingrevolt-production.com` :
    PRODUCTION_URL 
;

const FRONT_END_BASE_URL = `https://www.writingrevolt.com`;
const LEGACY_FRONT_END_BASE_URL = `https://www.creativerevolt.com`;

const QUERY_URL = `${BASE_URL}/graphql`

const TEMPLATES = {
    HOME: require.resolve(`../src/templates/home/index.jsx`),
    COURSES: require.resolve(`../src/templates/courses/index.jsx`),
    ABOUT: require.resolve(`../src/templates/about/index.jsx`),
    FREE_CLASSES: require.resolve(`../src/templates/free-classes/index.jsx`),
    BLOG_LANDING: require.resolve(`../src/templates/blog-landing/index.jsx`),
    BLOG_STANDARD: require.resolve(`../src/templates/blog-standard/index.jsx`),
    BLOG_ULTIMATE_GUIDE: require.resolve(`../src/templates/blog-ultimate-guide/index.jsx`),
    PAGE_NOT_FOUND: require.resolve(`../src/templates/page-not-found/index.jsx`),
    CONTACT: require.resolve(`../src/templates/contact/index.jsx`),
    RESULTS_AND_REVIEWS: require.resolve(`../src/templates/results-and-reviews/index.jsx`),
    GENERAL_CONTENT: require.resolve(`../src/templates/general-content/index.jsx`),
    NO_MATCH: require.resolve(`../src/templates/no-match/index.jsx`),
}

const BREAKPOINTS_INT = { // used outside of theme as well
    xs: 350,
    sm: 640,
    md: 1000,
    lg: 1280,
    xl: 1500
}

const BREAKPOINTS = { // used outside of theme as well
    xs: `${BREAKPOINTS_INT.xs}px`,
    sm: `${BREAKPOINTS_INT.sm}px`,
    md: `${BREAKPOINTS_INT.md}px`,
    lg: `${BREAKPOINTS_INT.lg}px`,
    xl: `${BREAKPOINTS_INT.xl}px`
}
  
module.exports = {
    BASE_URL,
    FRONT_END_BASE_URL,
    LEGACY_FRONT_END_BASE_URL,
    QUERY_URL,
    BREAKPOINTS,
    BREAKPOINTS_INT,
    TEMPLATES
}

