import React, { useEffect, useState } from 'react'

import BlogCard from '@blog/blog-card';
import Pagination from '@blog/pagination';
import DotLoader from '../../commons/dot-loader';

export default function BlogListing({
    activePosts = [], 
    disruptorLayout = {},
    location,
}) {
    const { disruptorModules = [], __typename } = disruptorLayout;
    const itemsPerPage = 4;

    const [blogCards, setBlogCards] = useState(null);

    useEffect(() => {
        if(activePosts.length) {
            let blogCardItems = [];
            blogCardItems = activePosts.map((post, i) => (
                <BlogCard 
                    module={post}
                    key={post.uri + i + 'blog-listing'}
                />
            ))
            setBlogCards(blogCardItems);
        }
    }, [activePosts])
    return (
        <section>
            <span className="sr-only">A listing of blog post for Writing Revolt</span>
            {blogCards ? 
                <Pagination
                    itemsPerPage={itemsPerPage}
                    items={blogCards}
                    enableSeperators={true}
                    disruptorModules={disruptorModules}
                    disruptorTypename={__typename}
                    disruptorId={'blog-listing'}
                    location={location}
                />
            : 
                <DotLoader
                    isLoading={true}
                    isLarge={true}
                    isAbsolute={false}
                    color={'black'}
                />
            }
        </section>
    )
}