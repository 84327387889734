import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

// TODO - should maybe rework this to only come from the styled component??
export const Containers = css`
    .container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 525px;

        ${theme.mediaQuerys.xsUp} {
            padding-left: 25px;
            padding-right: 25px;
        }

        ${theme.mediaQuerys.smUp} {
            max-width: 750px;
        }

        ${theme.mediaQuerys.mdUp} {
            max-width: 1050px;
        }

        ${theme.mediaQuerys.lgUp} {
            padding-left: 35px;
            padding-right: 35px;
        }


        &.outer-container {
            max-width: 550px;

            ${theme.mediaQuerys.smUp} {
                max-width: 850px;
            }

            ${theme.mediaQuerys.mdUp} {
                max-width: 1200px; // was 1180
            }
        }
    }

    .inner-content-container {
        padding-left: 20px;
        padding-right: 20px;

        ${theme.mediaQuerys.xsUp} {
            padding-left: 35px;
            padding-right: 35px;
        }
    }

    .blog-content-container {
        max-width: 840px;
        padding-left: 20px;
        padding-right: 20px;

        ${theme.mediaQuerys.xsUp} {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
`