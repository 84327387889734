import styled, { css } from 'styled-components';

export const StyledArrow = styled.div`
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 12px;
        height: 1px;
        width: 15px;
        background: white;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    ${props => props.direction === 'down' && css`
        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    `}

    ${props => props.direction === 'up' && css`
        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    `}
`