import React, { useEffect, useRef } from 'react';

const useIsWindow = (func, deps) => {
    const [isWindow, setIsWindow] = React.useState(false);

    useEffect(() => {
        if(typeof window !== undefined){
            setIsWindow(true);
        }
      
      return ()=> setIsWindow(false);
    }, []);
  
    return [isWindow];
}

export default useIsWindow;