import React from 'react'
import { StyledArrow } from './styles'

// direction options include:
// => down, and up
export default function Arrow({ direction = 'down', ...otherProps }) {
    return (
        <StyledArrow direction={direction} {...otherProps}>
            <span className="sr-only">Arrow Button</span>
        </StyledArrow>
    )
}
