import { useState } from 'react';

const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key = '') => {
  return getQuery().get(key);
};

const useQueryParam = (
  key = '',
  defaultVal = '',
  lowercase = true,
) => {
    const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

    const updateUrl = (newVal) => {
        newVal = newVal.toLowerCase();
        setQuery(newVal);

        const query = getQuery();

        if (newVal.trim() !== '') {
            query.set(key, newVal);
        } else {
            query.delete(key);
        }

        if (typeof window !== 'undefined') { // necessary for gatsby / ssr
            const { protocol, pathname, host } = window.location;
            let newUrl = `${protocol}//${host}${pathname}`;
            if(query.toString()) newUrl += `?${query.toString()}`;
            window.history.pushState({}, '', newUrl);
        }
    };

    return [query, updateUrl];
};

export default useQueryParam;