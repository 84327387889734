import React from 'react';
import { StyledHamburger } from './styles';

export default function Hamburger({ isOpen = false, isToggledByClick, ...otherProps}) {
    return (
        <StyledHamburger 
            isToggledByClick={isToggledByClick} 
            isOpen={isOpen} 
            {...otherProps} 
        >
            <span className="middle-line"></span>
            <span className="sr-only">Toggle mobile navigation</span>
        </StyledHamburger>
    )
}