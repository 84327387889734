import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledSearchBarContainer = styled.label`
    width: 100%;
    position: relative;
    height: 42px;
    display: flex;
    align-items: center;
`

export const StyledSearchButton = styled.button`
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.8rem;
    font-weight: 800;
    font-style: italic;
    font-family: ${theme.fonts.header};
    color: white;
    height: 42px;
    width: 105px;
    border-radius: 25px;
    background-color: black;
    transition: .25s ease color, .25s ease background-color;
    border: 1px solid;
    cursor: pointer;

    ${theme.mediaQuerys.mdUp} {
        width: 115px;
    }

    ${props => !props.isSubmitting && css`
        &:hover {
            border: 1px solid;
            color: black;
            background-color: white;
        }
    `}
`

export const StyledSearchInput = styled.input`
    display: block;
    background-color: #F4F4F4;
    border: none;
    font-size: 1.6rem;
    font-style: italic;
    min-height: 37px;
    padding: 8px 24px 8px 12px;
    width: calc(100% - 85px);
    margin-right: auto;
    
    ${theme.mediaQuerys.mdUp} {
        padding: 8px 35px 8px 12px;
    }

    &::-webkit-search-cancel-button{        
        -webkit-appearance: none;
    }

    &:focus {
        outline: none;
    }
`