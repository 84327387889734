import React from 'react'

import config from '@config';

import {
    PinterestShareCount,
} from 'react-share';

const numberFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
}
export default function ShareCount({ slug, withSlash = true }) {

    if (slug) return (
        <PinterestShareCount url={config.FRONT_END_BASE_URL + slug}>
            {baseShareCount =>
                <PinterestShareCount url={config.LEGACY_FRONT_END_BASE_URL + slug}>
                    {legacyShareCount => {
                        const totalShares = legacyShareCount + baseShareCount;

                        if(totalShares <= 0 || !totalShares) return null;
                        return `${withSlash ? ' / ' : ''}${numberFormatter(totalShares)} Shares`
                    }}
                </PinterestShareCount>
            }
        </PinterestShareCount>  
    )
    return null;
}
