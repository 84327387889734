import React, { useEffect } from 'react';
import { StyledBlogSocialShare } from './styles';

import { FaPinterest, FaFacebookF, FaTwitter } from 'react-icons/fa';
import useIsWindow from '@hooks/useIsWindow';

export default function BlogSocialShare({ socials = [], layout = 'horizontal' }) {

    const [isWindow] = useIsWindow();

    return (
        <StyledBlogSocialShare className={layout}>
            {layout === 'vertical' && 
                <div className="share-total">
                    873<br/>
                    shares
                </div>
            }

            <a 
                href={isWindow ?
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`
                    : `javascript:void(0)`
                }
                className={`social-cta facebook`}
                target="_blank"
            >
                <FaFacebookF className="social-icon" />
                <span>
                    Facebook
                </span>
            </a>

            <a 
                href={isWindow ?
                    `http://twitter.com/share?text=${encodeURI(document.title)}&url=${window.location.href}&via=jordenmakelle`
                    : `javascript:void(0)`
                }
                className={`social-cta twitter`}
                target="_blank"
            >
                <FaTwitter className="social-icon" />
                <span>
                    Tweet
                </span>
            </a>
        </StyledBlogSocialShare>
    )
}
