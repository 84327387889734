import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledBlogHeroContainer = styled.section`
    background-color: black;
    color: white;
    
    .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 135px;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;

        ${theme.mediaQuerys.smUp} {
            min-height: 150px;
        }

        ${theme.mediaQuerys.mdUp} {
            min-height: 165px;
            flex-direction: row;
        }
    }
`

export const StyledTitle = styled.h1`
    font-size: 2.2rem;
    margin-top: 0;
    margin-bottom: .4rem;
    font-weight: 800;
    text-align: center;    

    ${theme.mediaQuerys.smUp} {
        font-size: 3rem;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 4rem;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 4.5rem;
    }
`

export const StyledActiveOptionAndDropdownContainer = styled.div`
    display: inline;
    position: relative;
`

export const StyledActiveOption = styled.span`
    display: block;
    width: auto;
    position: relative;
    font-size: 3rem;
    padding-bottom: 6px;
    cursor: pointer;
    font-weight:800;
    text-align: center;
    text-transform: lowercase;

    ${theme.mediaQuerys.smUp} {
        font-size: 4rem;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-left: .75rem;
        padding-top: 3px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-left: 1.4rem;
        font-size: 4.5rem;
        padding-bottom: 10px;
        padding-top: 5px;
    }

    &::before { // underline design element
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid white;  
    }
`

export const StyledOptionsList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    width: 250px;
    max-height: 165px;
    overflow-y: scroll;
    background: white;
    color: black;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    padding: 0 0 8px 0;
    opacity: 0;
    z-index: 1;
    transform: translateY(5px);
    visibility: hidden;
    transition: .2s opacity, .2s transform, .2s visibility;

    ${props => props.isDropdownMenuOpen && css`
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        transition: .2s opacity, .2s transform;
    `}

    ${theme.mediaQuerys.xsUp} {
        width: 270px;
    }

    ${theme.mediaQuerys.smUp} {
        width: 380px;
        min-height: 245px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-left: .75rem;
    }

    ${theme.mediaQuerys.lgUp} {
        min-height: 320px;
        margin-left: .85rem;
        margin-top: 12px;
    }
`

export const StyledOptionItem = styled.li`
    font-size: 1.4rem;
    font-style: italic;
    list-style-type: none;
    padding: 12px 16px;
    cursor: pointer;
    text-transform: lowercase;

    ${theme.mediaQuerys.smUp} {
        font-size: 1.6rem;
        padding: 15px 20px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.8rem;
        padding: 16px 30px;
    }

    &.active, &:hover {
        background: #F4F4F4;
        color: #148692;
    }
`