import React from "react"

import { ThemeProvider } from "styled-components"
import fetch from 'isomorphic-fetch';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';

import { GlobalStyle } from "@styles/global.styles"
import { theme } from '@styles/theme.styles'

import Header from "./header"
import Footer from "./footer"
import SEO from "./seo.component"
import CookieConsentTracking from './cookie-consent-tracking'

const CONFIG = require('../../../node-lib/config');

const client = new ApolloClient({
  fetch,
  uri: CONFIG.QUERY_URL
})

export default function Layout({ children, location, pageContext: {
  seo,
  uri,
  isBlogPage
}}) {
  return (
    <ApolloProvider client={client}>   
      <ThemeProvider theme={theme}>  

        <CookieConsentTracking />
        <SEO seo={seo} uri={uri} isBlogPage={isBlogPage} />
        <GlobalStyle />

        <span id="top-of-page" aria-hidden="true"/>
        
        <Header location={location} />
          {children}
        <Footer />

      </ThemeProvider>
    </ApolloProvider>
  )
}
