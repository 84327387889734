import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';
import { StyledModuleContainer } from '@styles/global-components/_containers';

export const StyledHeroContainer = styled(StyledModuleContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 0px;
    /* background-color: ${props => props.backgroundColor || '#AF1E87'}; */
    min-height: 275px;

    ${theme.mediaQuerys.smUp} {
        min-height: 350px;
    }

    ${theme.mediaQuerys.mdUp} {
        min-height: 480px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: 35px;
        padding-bottom: 15px;
        min-height: 500px;
    }

    .hero-text-content {
        ${theme.mediaQuerys.smUp} {
            width: 50%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 45%;
        }
    }

    .social-share-desktop {
        top: 50px;
    }
`

export const StyledHeroTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

export const StyledHeroMiddleContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 950px;

    ${theme.mediaQuerys.smUp} {
        flex-direction: row;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 20px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 30px;
    }
`

export const StyledImageWrapper = styled.div`
    width: 100%;
    /* max-width: 100%; */
    margin-top: 20px;
    max-width: 400px;

    ${theme.mediaQuerys.smUp} {
        width: 50%;
        padding-right: 5%;
        margin: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 55%;
        padding-right: 8%;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    width: 100%;
    /* max-width: 100%;
    margin-top: 20px;
    max-width: 400px;

    ${theme.mediaQuerys.smUp} {
        width: 50%;
        padding-right: 5%;
        margin: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 55%;
        padding-right: 8%;
    } */
`