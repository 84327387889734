import React from 'react'
import { StyledTextContentContainer, StyledContentContainer, StyledLinksContainer, StyledSubText, StyledFeaturedImage, StyledHero, StyledTitle, StyledBottomContentContainer } from './styles'

export default function ResultsAndReviewsHero({
    module,
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            isOverflowHidden={true}
            id={module.sectionOptions && module.sectionOptions.sectionId}
        >
            <StyledContentContainer className="container outer-container">
                {module.featuredImage &&
                    <StyledFeaturedImage 
                        className="desktop"
                        useGatsbyImage={false}
                        imageFile={module.featuredImage.imageFile} 
                        alt={module.featuredImage.altText}
                    />
                }
                

                <StyledTextContentContainer>           
                    {module.title && 
                        <StyledTitle
                            titleFontOptions={{ mobile: 'lg', desktop: 'md'}}
                            renderAs={'h1'}
                        > 
                            {module.title}
                        </StyledTitle>
                    }

                    <StyledBottomContentContainer>
                        {module.featuredImage &&
                            <StyledFeaturedImage 
                                className="mobile"
                                useGatsbyImage={false}
                                imageFile={module.featuredImage.imageFile} 
                                alt={module.featuredImage.altText}
                            />
                        }


                        {module.text && 
                            <StyledSubText
                                bodyFontOptions={{ mobile: 'sm', desktop: 'lg' }}
                            >
                                {module.text}
                            </StyledSubText>
                        }

                 
                        {module.links && 
                            <StyledLinksContainer
                                bodyFontOptions={{ mobile: 'sm', desktop: 'lg' }}
                            >
                                {module.links.map(({ sectionIdLink, linkText }) => (
                                    <a href={`#${sectionIdLink}`}>
                                        {linkText}
                                    </a>
                                ))}
                            </StyledLinksContainer>
                        }
                        
                    </StyledBottomContentContainer>
                </StyledTextContentContainer>  
            </StyledContentContainer>
        </StyledHero>
    )
}
