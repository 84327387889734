import React from 'react'

import parse, { domToReact, attributesToProps } from 'html-react-parser';

import ShortCodes from '@shortcodes';
import { getBlockTag, getTypographyField } from './helpers';
// TODO - Add list of shortcodes here

export default function HtmlParser({ 
    bodyFontOptions,
    titleFontOptions,
    stripBlockTags = false,
    children
}) {
  
    const options = {
        replace: (elNode) => {
            const { name, type, children, attribs } = elNode;
            
            if(type === 'tag') {

                const isInlineElement = !getBlockTag(name);
                if(stripBlockTags && !isInlineElement) { // this option strips non inline elements
                    return (<>{domToReact(children, options)}</>);
                }

                if(name === 'sc') { // matched shortcode component
                    return (<ShortCodes {...attribs} />)
                }

                // map tag to styled component
                const props = attributesToProps(attribs);
                const Field = getTypographyField(name);
                
                if(!Field) return null;

                if(name === 'img') return (<Field $useInlineImage={stripBlockTags} {...props}/>)
                return (
                    <Field 
                        {...props}
                        bodyFontOptions={bodyFontOptions}
                        titleFontOptions={titleFontOptions}
                        // fontSize={fontSize}
                    >
                        {domToReact(children, options)}
                    </Field>
                );
            } 
        }
    }
    
    if(!children) return null;
    return (     
        parse(children, options)
    )
}


