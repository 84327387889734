import styled, { css } from 'styled-components';
import { theme } from '@theme';
import { StyledModuleContainer } from '@styles/global-components/_containers';

import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';

const content_spacing = '25px'; // mobile first
const lg_content_spacing = '30px'; // was 40px

const md_content_width = '60%'; // includes title
const lg_content_width = '55%'; // includes title

const module_spacing = '30px';
const lg_module_spacing = '55px';

export const StyledHero = styled(StyledModuleContainer)`
    position: relative;
    z-index: 0;
    padding-top: ${module_spacing};
    padding-bottom: ${module_spacing};
    overflow: hidden;

    ${theme.mediaQuerys.smUp} {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledContentContainer = styled.div`

    ${theme.mediaQuerys.mdUp} {
        display: flex;
        align-items: flex-end;
    }
`

export const StyledTextContentContainer = styled.div`

    ${theme.mediaQuerys.mdUp} {
        padding-left: 50px;
        width: ${md_content_width};
    }

    ${theme.mediaQuerys.lgUp} {
        width: ${lg_content_width};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-size: 3rem;
    line-height: 37px;
    font-weight: 900;
    margin: 0;
    padding-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        /* font-size: 4.5rem;
        line-height: 55px; */
        font-size: 4rem;
        line-height: 48px;
        padding-right: 50px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 4.5rem;
        line-height: 55px;
        /* font-size: 6rem;
        line-height: 72px; */

        padding-right: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 4.8rem;
        line-height: 68px;
        /* font-size: 7rem;
        line-height: 85px; */
        padding-bottom: ${lg_content_spacing};
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    position: absolute !important; // overrides gatsby image
    bottom: 0;
    right: -110px;
    width: 80%;
    /* max-width: 300px; */
    max-width: 280px;
    z-index: -1;

    ${theme.mediaQuerys.smUp} {
        right: 0;
        /* max-width: 325px; */
        max-width: 250px;
    }

    ${theme.mediaQuerys.mdUp} {
        position: relative !important;
        margin-bottom: -${module_spacing};
        /* max-width: 550px; */
        max-width: 425px;
        width: 40%;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: -${lg_module_spacing};
        width: 45%;
    }
`

export const StyledSubText = styled.div`
    font-size: 1.4rem;
    line-height: 24px;
    padding-right: 30%;
    /* min-height: 320px; */
    min-height: 250px;

    ${theme.mediaQuerys.xsUp} {
        padding-right: 35%;
    }

    ${theme.mediaQuerys.smUp} {
        padding-right: 35%;
        /* font-size: 1.8rem;
        line-height: 30px; */
        font-size: 1.6rem;
        line-height: 27px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 2.2rem;
        line-height: 38px; */
        font-size: 1.8rem;
        line-height: 30px;
        padding-right: 20px;
    }

    p {
        margin: 0 0 ${content_spacing} 0;

        ${theme.mediaQuerys.lgUp} {
            margin: 0 0 ${lg_content_spacing} 0;
        }
    }
`