import React from 'react'
import { StyledMiddleContainer, StyledLeftContainer, StyledRightContainer, StyledMobileNavItem, StyledSearchContainer, StyledText, StyledImageCta, StyledFooter, StyledContentContainer, StyledSocialsContainer, StyledDesktopNavList, StyledNavItem, StyledMobileNavList, StyledImageCtaTitle, StyledImageCtaText, StyledImageCtaImage, StyledBottomBar, StyledCopyright, StyledButton } from './styles';

import HtmlParser from '@commons/html-parser';
import SocialShare from '@commons/social-share'
import SearchBar from '@commons/search-bar'

import { useStaticQuery, graphql } from "gatsby";

export default function Footer() {
    const footerData = useStaticQuery(
        graphql`
          query {
            wpgraphql {
              footer {
                footerLayout {
                    buttonLink {
                        title
                        url
                    }
                    buttonOptions {
                        buttonBackgroundColor
                        buttonShadowColor
                        buttonTextColor
                        enableButtonOptions
                    }
                    middleColumnText
                    rightColumnTitle
                    rightColumnText
                    copyrightText
                    rightColumnImage {
                        altText
                        mediaItemUrl
                        imageFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData (
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                    links {
                        link {
                            title
                            url
                        }
                    }
                    socials {
                        icon
                        link {
                            url
                        }
                    }
                }
              }
            }
          }
        `
      )
    
      const {
        buttonLink,
        buttonOptions,
        links = [],
        middleColumnText,
        rightColumnText,
        rightColumnTitle,
        rightColumnImage,
        socials = [],
        copyrightText
      } = footerData.wpgraphql.footer.footerLayout

    return (
        <StyledFooter>
            <StyledContentContainer type={'outer'}>
                <StyledLeftContainer>
                    {socials && 
                        <StyledSocialsContainer>
                            <SocialShare socials={socials} />
                        </StyledSocialsContainer>
                    }
                   

                    {links && 
                        <StyledDesktopNavList>
                            {links.map(({ link: { title, url }}, i) => (
                                <li key={title + url + i + 'footer-nav-desktop'}>
                                    <StyledNavItem 
                                        href={url}
                                        className="h_text-underline h_underline-bold"
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    />
                                </li>
                            ))}
                        </StyledDesktopNavList>    
                    }
                              

                    <StyledSearchContainer>
                        <SearchBar />
                    </StyledSearchContainer>
                </StyledLeftContainer>

                <StyledMiddleContainer>
                    {buttonLink && 
                        <StyledButton 
                            href={buttonLink.url} 
                            buttonOptions={buttonOptions}
                            radius={'md'}
                        >
                            {buttonLink.title}
                        </StyledButton>
                    }
              
                    {middleColumnText && 
                        <StyledText
                            bodyFontOptions={{ mobile: 'xs', desktop: 'xs' }}
                            textSpacing={'sm'}
                        >
                            {middleColumnText}
                        </StyledText>
                    }
                    
                </StyledMiddleContainer>
                <StyledRightContainer>
                    {links && 
                        <StyledMobileNavList>
                            {links.map(({ link: { title, url }}, i) => (
                                <li key={title + url + i + 'footer-nav-mobile'}>
                                    <StyledMobileNavItem 
                                        href={url}
                                    >
                                        <span 
                                            dangerouslySetInnerHTML={{ __html: title }}     
                                        />
                                    </StyledMobileNavItem>
                                </li>
                            ))}
                        </StyledMobileNavList>
                    }
                    
                    <StyledImageCta>
                        {rightColumnTitle && 
                            <StyledImageCtaTitle
                                bodyFontOptions={{ mobile: 'md', desktop: 'sm' }}
                                textSpacing={'sm'}
                            >
                                {rightColumnTitle}
                            </StyledImageCtaTitle>
                        }
                        
                        {rightColumnImage && 
                            <StyledImageCtaImage 
                                imageFile={rightColumnImage.imageFile} 
                                alt={rightColumnImage.altText}
                            />
                        }
                        
                        {rightColumnText && 
                            <StyledImageCtaText
                                bodyFontOptions={{ mobile: 'xs', desktop: 'xs' }}
                                textSpacing={'sm'}
                            >
                                {rightColumnText}
                            </StyledImageCtaText>
                        }               
                    </StyledImageCta>
                </StyledRightContainer>
              
            </StyledContentContainer>
            
            <StyledBottomBar>
                <HtmlParser stripBlockTags={true}>
                    {copyrightText}
                </HtmlParser>
            </StyledBottomBar>
        </StyledFooter>
    )
}
