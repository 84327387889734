import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

export const Helpers = css`

    .clickable {
        cursor: pointer;
    }

    .no-scroll {
        overflow: hidden;
    }

    .relative {
        position: relative;
    }

    .fluid {
        width: 100%;
    }

    .sr-only {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }

    .mobile-nav-no-scroll {
        overflow: hidden;
    }

    .title-font {
        font-family: ${theme.fonts.header};
    }
`