import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';

export const StyledPaginationButtons = styled(StyledContainer)`
    margin-top: 35px;
    margin-bottom: 35px;

    ${theme.mediaQuerys.mdUp} {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &, .pagination-numbers-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
`

export const StyledPaginationButton = styled.button`
    position: relative;
    /* display: flex; */
    display: none;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    padding: 8px 20px 10px 20px;
    /* margin-right: 2px; */
    min-height: 40px;
    text-align: center;
    border-radius: 15px;
    font-size: 1.8rem;
    font-weight: 500;
    cursor: ${props => props.$isDisabled ? 'auto' : 'pointer'};
    transition: .25s ease;
    border: 2px solid;

    ${theme.mediaQuerys.smUp} {
        margin-right: 5px;
    }

    ${theme.mediaQuerys.mdUp} {
        min-height: 50px;
        margin-right: 10px;
    }

    &.prev, &.next {
        display: flex;
        
        ${theme.mediaQuerys.mdUp} {
            padding: 8px 30px 10px 30px;
        }
    }

    &.next {
        margin-right: 0;
    }

    ${props => props.$isActiveOnMobile && css`
        display: flex;
    `}

    ${props => props.$isActiveOnTablet && css`
        ${theme.mediaQuerys.smUp} {
            display: flex;
        }
        
    `}

    ${props => props.$isActiveOnDesktop && css`
        ${theme.mediaQuerys.mdUp} {
            display: flex;
        }
    `}

    ${props => !props.$isDisabled && css`
        &:hover {
            background-color: ${theme.colors.white};
            color: ${theme.colors.black};
        }
    `}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.colors.black};
        opacity: 0;
        transition: .25s opacity ease;
        border-radius: 15px;

        ${props => props.$isDisabled && css`
            opacity: .5;
        `}
    }

`