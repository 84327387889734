import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

const bodyMobileXs = css`
    font-size: 1.2rem;
    line-height: 16px;
`

const bodyMobileSm = css`
    font-size: 1.4rem;
    line-height: 23px;
`

const bodyMobileMd = css`
    font-size: 1.6rem;
    line-height: 23px;
`

const bodyMobileLg = css`
    font-size: 1.8rem;
    line-height: 24px;
`


const bodyDesktopXs = css`
    ${theme.mediaQuerys.smUp} {
        font-size: 1.4rem;
        line-height: 18px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.6rem;
        line-height: 20px;
    }   
`

const bodyDesktopSm = css`
    ${theme.mediaQuerys.smUp} {
        font-size: 1.6rem;
        line-height: 23px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 1.8rem;
        line-height: 24px;
    }   
`

const bodyDesktopMd = css`
    ${theme.mediaQuerys.smUp} {
        font-size: 1.8rem;
        line-height: 24px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 2rem;
        line-height: 27px;
    }      
`

const bodyDesktopLg = css` // this should get readjusted

    ${theme.mediaQuerys.smUp} {
        font-size: 1.6rem;
        line-height: 23px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.8rem;
        line-height: 29px;
    }
`

const bodyDesktopXl = css`

    ${theme.mediaQuerys.smUp} {
        font-size: 2rem;
        line-height: 27px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 2.2rem;
        line-height: 33px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 2.5rem;
        line-height: 36px;
    }  
`


const titleMobileSm = css`
    font-size: 2rem;
    line-height: 26px;
`

const titleMobileMd = css`
    font-size: 2.2rem;
    line-height: 27px;
`

const titleMobileLg = css`
    font-size: 2.5rem;
    line-height: 30px;
`

// Jorden test edits

const titleDesktopXs = css`

    ${theme.mediaQuerys.smUp} {
        font-size: 2.3rem;
        line-height: 29px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }    
`

const titleDesktopSm = css`

     ${theme.mediaQuerys.smUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 3.3rem;
        line-height: 41px;
    }
`

const titleDesktopMd = css`

    ${theme.mediaQuerys.smUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 3.2rem;
        line-height: 38px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 3.8rem;
        line-height: 47px;
    }
`

const titleDesktopLg = css`
    ${theme.mediaQuerys.smUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 3.8rem;
        line-height: 47px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 4.3rem;
        line-height: 56px;
    }
`


// this size should get removeddddd
const titleDesktopXl = css`

${theme.mediaQuerys.smUp} {
        font-size: 2.8rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 3.2rem;
        line-height: 38px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 3.8rem;
        line-height: 47px;
    }
`

const titleDesktopXxl = css`
    ${theme.mediaQuerys.smUp} {
        font-size: 3.1rem;
        line-height: 40px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 4.3rem;
        line-height: 54px;
    }

    ${theme.mediaQuerys.lgUp} {
        font-size: 5.8rem;
        line-height: 74px;
    }
`

const mobileTitleSizes = {
    sm: titleMobileSm,
    md: titleMobileMd,
    lg: titleMobileLg,
}

const desktopTitleSizes = {
    xs: titleDesktopXs,
    sm: titleDesktopSm,
    md: titleDesktopMd,
    lg: titleDesktopLg,
    xl: titleDesktopXl,
    xxl: titleDesktopXxl,
}

const mobileBodySizes = {
    xs: bodyMobileXs,
    sm: bodyMobileSm,
    md: bodyMobileMd,
    lg: bodyMobileLg,
}

const desktopBodySizes = {
    xs: bodyDesktopXs,
    sm: bodyDesktopSm,
    md: bodyDesktopMd,
    lg: bodyDesktopLg,
    xl: bodyDesktopXl,
}


export const getTitleFontSize = ({mobile = 'lg', desktop = 'sm'}) => {

    return css`
        ${mobileTitleSizes[mobile]}
        ${desktopTitleSizes[desktop]}
    `;
};

export const getBodyFontSize = ({mobile = 'md', desktop = 'sm'}) => {

    return css`
        ${mobileBodySizes[mobile]}
        ${desktopBodySizes[desktop]}
    `;
};