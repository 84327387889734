import React, { useEffect, useRef, useState } from 'react'
import { StyledActiveOptionAndDropdownContainer, StyledBlogHeroContainer, StyledOptionItem, StyledTitle, StyledActiveOption, StyledOptionsList} from './styles';

import Arrow from '@commons/arrow'

export default function BlogLandingHero({ 
    title, 
    options = [], 
    activeOption,
    filterPostsData
}) {

    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

    const toggleDropdownMenu = () => setIsDropdownMenuOpen(!isDropdownMenuOpen);
    const closeDropdownMenu = () => setIsDropdownMenuOpen(false);

    const activeOptionButtonRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    const closeDropdownMenuOnOutsideClicks = (e) => {
        const isClickable = activeOptionButtonRef.current.contains(e.target);
        if(!isClickable) {
            closeDropdownMenu();
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeDropdownMenuOnOutsideClicks, false);
        return (() => {
            window.removeEventListener('click', closeDropdownMenuOnOutsideClicks, false);
        })
    }, [])

    useEffect(() => {
        if(isDropdownMenuOpen) // reset dropdown scroll when opened
            dropdownMenuRef.current.scrollTo(0,0);
    }, [isDropdownMenuOpen])

    return (
        <StyledBlogHeroContainer>
            <div className="content-container container">
                <StyledTitle>
                    {title}
                    <span className="sr-only">Everything</span>
                </StyledTitle> 
                <StyledActiveOptionAndDropdownContainer>
                    
                    <StyledActiveOption 
                        ref={activeOptionButtonRef}
                        onClick={toggleDropdownMenu}
                        className="active-option title-font"
                    >
                        {activeOption}
                        . 
                        <Arrow 
                            direction={isDropdownMenuOpen ? 'up' : 'down'}
                            style={{ marginLeft: '5px', marginBottom: '-2px' }}
                        />
                    </StyledActiveOption>


                    <StyledOptionsList
                        ref={dropdownMenuRef}
                        isDropdownMenuOpen={isDropdownMenuOpen}
                     >
                        {options.map(({ name }, i) => (
                            <StyledOptionItem 
                                onClick={() => filterPostsData(name)}
                                className={name.toLowerCase() === activeOption ? 'active' : ''}
                                key={name + i + 'blog-landing'}
                            >
                                {name}    
                            </StyledOptionItem>
                        ))}
                    </StyledOptionsList>   
                </StyledActiveOptionAndDropdownContainer>     
            </div>         
        </StyledBlogHeroContainer>
    )
}
