import React from 'react'

import Hero from '@modules/hero/page-not-found-hero';
import Modules from '@modules';

export default function PageNotFoundTemplate({
    pageContext: {
      pageData = {
          pageNotFoundLayout: {},
          modularLayout: {},
      },
    }
  }) {

    const {
        hero
    } = pageData.pageNotFoundLayout;

    const {
        __typename,
        modules
    } = pageData.modularLayout

    return (
        <main className="page-not-found__template">
            <Hero 
                module={hero}
            />

            <Modules 
                modules={modules}
                typeName={__typename}
                id={'page-not-found-template'}
            />
        </main>
    )
}
