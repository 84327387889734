import React from 'react'

import { StyledWysiwygContent } from './styles'

import HtmlParser from '@commons/html-parser';

const getCorrectFontSizing = ({
    fontOverrides,
    bodyFontOptions,
    titleFontOptions
}) => {
    if(fontOverrides) return fontOverrides;

    return {
        body: bodyFontOptions,
        title: titleFontOptions,
    }
}

export default function WysiwygContent({ 
    content, 
    children,
    textColor, 
    textAlign,
    className = '',
    bodyFontOptions,
    titleFontOptions,
    fontOverrides,  
    textSpacing = 'md',
    disableTextSpacing = false
}) {

    const formattedFontOptions = getCorrectFontSizing({
        bodyFontOptions,
        titleFontOptions,
        fontOverrides
    })

    return (
        <StyledWysiwygContent
            className={className}
            textColor={textColor}
            fontOverrides={fontOverrides}
            textSpacing={textSpacing}
            textAlign={textAlign}
            bodyFontOptions={formattedFontOptions.body}
            disableTextSpacing={disableTextSpacing}
        >
            <HtmlParser 
                bodyFontOptions={formattedFontOptions.body}
                titleFontOptions={formattedFontOptions.title}
            >
                {content || children}
            </HtmlParser>
        </StyledWysiwygContent>        
    )
}


