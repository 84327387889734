import styled, { css } from 'styled-components';
import { bodyFontSizing } from '@styles/global-components/_typography';
import { theme } from '@styles/theme.styles';

import PointerIcon from '@images/pointer-icon.svg';
// const smTextSpacing = css`
//     margin: 0 0 20px 0;

//     ${theme.mediaQuerys.lgUp} {
//         margin: 0 0 30px 0;
//     }
// `

const smTextSpacing = css`
    margin: 0 0 20px 0;

    ${theme.mediaQuerys.lgUp} {
        margin: 0 0 25px 0;
    }
`

const mdTextSpacing = css`
    margin: 0 0 25px 0;

    ${theme.mediaQuerys.lgUp} {
        margin: 0 0 30px 0;
    }
`

const lgTextSpacing = css`
     margin: 0 0 25px 0;

    ${theme.mediaQuerys.lgUp} {
        margin: 0 0 40px 0;
    }
`

const textSpacing = {
    sm: smTextSpacing,
    md: mdTextSpacing,
    lg: lgTextSpacing
}

const getTextSpacing = (size = 'md') => textSpacing[size];

export const StyledWysiwygContent = styled.div`
    color: ${props => props.textColor || ''};
    text-align: ${props => props.textAlign || ''};
    ${bodyFontSizing};

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }

    p, blockquote, dd, h1, h2, h3, h4, h5, h6, ul, ol, table, .wysiwyg-spacer {
        ${props => props.fontOverrides && props.fontOverrides.enableFontOverrides ? 
            getTextSpacing(props.fontOverrides.textSpacing)
        : props.textSpacing ?
            getTextSpacing(props.textSpacing)
        : 
            getTextSpacing()
        }

        ${props => props.disableTextSpacing && css`
            margin: 0 !important;
        `}
    }

    ul.no-spacing li {
        margin: 0;
    }

    img {
        padding: 5px 0;
    }

    // START | Wordpress styling helpers
    .alignright {
        float: right;

        &img {
            margin-bottom: 1rem;
            padding-right: 1rem;
        }
    }

    .alignleft {
        float: left;

        img {
            margin-bottom: 1rem;
            padding-left: 1rem;
        }
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wp-caption {
        max-width: 100%;
        text-align: center;
    }

    img.emoji {
        height: 1em;
        width: auto;
    }

    .featured-list li {
        position: relative;
        list-style-type: none;
        padding-left: 55px;

        ${theme.mediaQuerys.smUp} {
            padding-left: 45px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -5px;
            background-image: url("/pointer-icon.svg");
            background-size: contain;
            background-repeat: no-repeat;

            height: 50px;
            width: 40px;
            display: inline-block;

            ${theme.mediaQuerys.smUp} {
                left: -17.5px;
            }
        }
    }

    iframe {
        max-width: 100%;
    }

    div {
        max-width: 100%;
    }

    textarea {
        background-color: #fff;
        border: 1px solid #bbb;
        padding: 2px;
        color: #4e4e4e;
        max-width: 100%;
    }

    &.blog-page {
        a {
            color: ${theme.colors.purple};
        }

        h1, h2, h3, h4, h5, h6 {
            a {
                color: inherit;
            }
        }

        ul, ol {
            /* padding-left: 25px; */

            ${theme.mediaQuerys.lgUp} {
                padding-left: 30px;
            }
        }

        li {
            margin-bottom: 15px;

            ${theme.mediaQuerys.lgUp} {
                margin: 0 0 20px 0;
            }
        }
    }

    .homepage-content-gray-title {
        font-family: ${theme.fonts.header};
        font-weight: 900;
        font-size: 2rem;
        line-height: 27px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 2.2rem;
            line-height: 30px;
        }
    }

    .homepage-content-gray-sub-title {
        font-size: 1.6rem;
        line-height: 20px;

        ${theme.mediaQuerys.mdUp} {
            font-size: 1.8rem;
            line-height: 22px;
        }
    }
`

