import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';
import WrButton from '@commons/wr-button';
import WrLink from '@commons/wr-link';
import WrImage from '@commons/wr-image';
import WysiwygContent from '@commons/wysiwyg-content';

const content_spacing = '20px';
const content_spacing_small = '15px';
const tablet_top_bar_spacing = '30px';

const md_content_spacing = '25px';
// const md_column_spacing = '2.5%';

export const StyledFooter = styled.footer`
    background-color: ${props => props.backgroundColor || ''};
    color: ${props => props.textColor || theme.colors.black};
    border-top: 3px solid black;
`

export const StyledContentContainer = styled(StyledContainer)`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    ${theme.mediaQuerys.smUp} {
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    ${theme.mediaQuerys.mdUp} {
        flex-direction: row;

        padding-top: 40px;
        padding-bottom: 45px;
    }
`

export const StyledLeftContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;

    ${theme.mediaQuerys.smUp} {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${tablet_top_bar_spacing};
    }

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% / 3);
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0;
        padding-right: 3%;
    }
`

export const StyledMiddleContainer = styled.div`
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: 40%;
    }

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% / 3);
        padding: 0 1.5%;
    }
`

export const StyledRightContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        width: 60%;
        margin: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% / 3);
        padding-left: 3%;
    }
`

export const StyledSocialsContainer = styled.div`

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
    }
`

export const StyledDesktopNavList = styled.ul`
    display: none;
    padding: 0;
    margin: 0;

    ${theme.mediaQuerys.mdUp} {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    li {
        list-style-type: none;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 20px;
        }
    }
`

export const StyledMobileNavList = styled.ul`
    width: 50%;
    margin: 0;
    padding: 0 5px 0 0;

    ${theme.mediaQuerys.smUp} {
        width: auto;
    }

    ${theme.mediaQuerys.mdUp} {
        display: none;
    }
    
    li {
        list-style-type: none;
        margin-bottom: 4px;
    }
`

export const StyledNavItem = styled(WrLink)`
    font-size: 2rem;
    color: black;
    text-decoration: none;
    font-weight: 800;
    font-family: ${theme.fonts.header};
    font-style: italic;

    /* &:hover {
        text-decoration: underline;
    } */
`

export const StyledMobileNavItem = styled(WrLink)`  
    display: inline-block;
    font-size: 2.5rem;
    padding: 3px 10px 8px 10px;
    border: 2px solid black;
    background: black;
    color: white;
    font-style: italic;
    text-decoration: none;
    font-weight: 900;
    font-family: ${theme.fonts.header};
    transition: .2s ease;

    span {
        position: relative;
        padding: 0 2px 0 1px;
    }

    span::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-bottom: 2px solid;
    }

    &:hover {
        background: white;
        /* border: 1px solid  */
        color: black;
    }
`

export const StyledSearchContainer = styled.div`

    width: 100%;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        min-width: 280px;
        width: 40%;
        max-width: 350px;
        /* width: 300px; */
        margin: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 100%;
        max-width: 320px;
    }
`

export const StyledButton = styled(WrButton)`
    font-size: 1.4rem;
    min-height: 44px;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
    }
`

export const StyledText = styled(WysiwygContent)`
    /* font-size: 1.2rem;
    line-height: 14px; */
    padding: 10px 30px 0 30px;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        padding: 10px 0 0 0;
        margin-bottom: 0;
    }

    /* ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
        line-height: 18px;
    } */
/* 
    p {
        margin: 0 0 ${content_spacing} 0;
    }

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    } */
`



export const StyledImageCta = styled.div`
    width: 50%;
    padding-left: 5px;

    ${theme.mediaQuerys.smUp} {
        flex: 1;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 5%;
    }

    ${theme.mediaQuerys.mdUp} {
        padding: 0;
    }
`

export const StyledImageCtaTitle = styled(WysiwygContent)`
    /* font-size: 1.6rem;
    line-height: 18px; */
    font-weight: 800;
    margin-bottom: ${content_spacing_small};
    font-family: ${theme.fonts.header};
    text-decoration: underline;

    ${theme.mediaQuerys.smUp} {
        max-width: 300px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 1.8rem;
        line-height: 22px; */
        max-width: unset;
        text-decoration: none;
    }
`

export const StyledImageCtaImage = styled(WrImage)`
    margin-bottom: ${content_spacing_small};
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        max-width: 220px;
    }
`

export const StyledImageCtaText = styled(WysiwygContent)`
    /* font-size: 1.2rem;
    line-height: 14px; */
    font-style: italic;

    ${theme.mediaQuerys.smUp} {
        max-width: 300px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 1.6rem;
        line-height: 19px; */
        max-width: unset;
    }

    p {
        margin: 0;
    }
`

export const StyledBottomBar = styled.div`
    background-color: ${props => props.backgroundColor || theme.colors.black};
    color: ${props => props.textColor || theme.colors.white};
    padding: 15px 5px;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-style: italic;

    ${theme.mediaQuerys.mdUp} {
        padding: 20px 0;
        font-size: 1.2rem;
    }

    p {
        margin: 0;
    }
`