import React from 'react'
import { StyledTextWithBackgroundContainer, StyledImageWithTextContainer, StyledFeaturedImage, StyledHero, StyledStarIcon, StyledSubTextBottom, StyledSubTextTop, StyledTextWithBackground, StyledTitle, StyledTitleIcon } from './styles'

import StarsIcon from '@images/stars-icon.png';
import WrImage from '@commons/wr-image'

export default function AboutHero({
    module,
    title,
    titleIcon,
    subTextTop,
    subTextBottom,
    textWithBackground,
    featuredImage,
}) {
    return (
        <StyledHero
            $sectionOptions={module.sectionOptions}
            id={module.sectionOptions && module.sectionOptions.sectionId}
        >
            <div className="container outer-container relative">
                {module.title && 
                    <StyledTitle 
                        titleFontOptions={{ mobile: 'sm', desktop: 'md' }}
                        renderAs={'h1'}
                    >
                        {module.title}
                    </StyledTitle>          
                }
                 <StyledImageWithTextContainer>
                    {module.featuredImage &&
                        <StyledFeaturedImage>
                            <WrImage 
                                useGatsbyImage={false}
                                imageFile={module.featuredImage.imageFile} 
                                alt={module.featuredImage.altText}
                            />
                        </StyledFeaturedImage>
                    }
                     {module.textTop && 
                        <StyledSubTextTop 
                            bodyFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                            dangerouslySetInnerHTML={{ __html: module.textTop }}
                        >
                            {module.textTop}
                        </StyledSubTextTop>
                    }
                 </StyledImageWithTextContainer>
                {module.textBottom && 
                    <StyledSubTextBottom
                        bodyFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                    >
                        {module.textBottom}
                    </StyledSubTextBottom>
                }
                {module.textCta && 
                    <StyledTextWithBackgroundContainer>
                        <StyledStarIcon isBottomLeft={true} src={StarsIcon} />
                        
                            <StyledTextWithBackground 
                                $backgroundColor={module.textCtaBackgroundColor || '#117782'}
                                $textColor={module.textCtaTextColor || 'white'}
                                renderAs="p"
                            >
                                {module.textCta}
                            </StyledTextWithBackground>
                        
                        <StyledStarIcon isTopRight={true} src={StarsIcon} />  
                    </StyledTextWithBackgroundContainer>
                  }
            </div>
        </StyledHero>
    )
}
