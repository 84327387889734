import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledSocialShare = styled.div`
    display: flex;
    align-items: center;

    .social-icon-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
        border: 1px solid;
        transition: .2s ease;

        ${theme.mediaQuerys.mdUp} {
            margin-right: 8px;
        }

        &:last-of-type {
            margin: 0;
        }

        svg {
            margin-left: -1px;
            margin-bottom: -1px;
            width: 15px;
            height: auto;
            color: white;
            transition: .2s ease;
        }
    }

    /* .social-icon {
        color: white; 
    } */

    .facebook {
        background: ${theme.colors.facebook};
        color: ${theme.colors.facebook};

        &:hover {
            background-color: white;

            svg {
                color: ${theme.colors.facebook};
            }
        }
    }

    .instagram {
        background: ${theme.colors.instagram};
        color: ${theme.colors.instagram};

        &:hover {
            background-color: white;

            svg {
                color: ${theme.colors.instagram};
            }
        }
    }

    .pinterest {
        background: ${theme.colors.pinterest};
        color: ${theme.colors.pinterest};

        &:hover {
            background-color: white;

            svg {
                color: ${theme.colors.pinterest};
            }
        }
    }

    .youtube {
        background: ${theme.colors.youtube};
        color: ${theme.colors.youtube};

        &:hover {
            background-color: white;

            svg {
                color: ${theme.colors.youtube};
            }
        }
    }

    .twitter {
        background: ${theme.colors.twitter};
        color: ${theme.colors.twitter};

        &:hover {
            background-color: white;

            svg {
                color: ${theme.colors.twitter};
            }
        }
    }


    /* } */
`