import React from 'react';
import { FaPinterestP, FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

export function matchSocialContent(socialName) {
    const socialContent = {
        facebook: {
            icon: <FaFacebookF className="social-icon" />,
            text: 'Facebook',
            href: 'https://google.com'
        },
        twitter: {
            icon: <FaTwitter className="social-icon" />,
            text: 'Tweet',
            href: 'https://google.com'
        },
        pinterest: {
            icon: <FaPinterestP className="social-icon" />,
            text: 'Pin',
            href: 'https://google.com'
        },
        youtube: {
            icon: <FaYoutube className="social-icon" />,
            text: 'Youtube',
            href: 'https://google.com'
        },
        instagram: {
            icon: <FaInstagram className="social-icon" />,
            text: 'Instagram',
            href: 'https://google.com'
        }
    }

    const selectedSocialContent = socialContent[socialName];

    return selectedSocialContent ? selectedSocialContent : null;
}