/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import './src/styles/global.scss';

import CustomLayout from "./wrapPageElement"

export const wrapPageElement = CustomLayout

if (typeof window !== 'undefined') {
    // Makes scroll behavior of internal links smooth
    require('smooth-scroll')('a[href*="#"]');
}

export const shouldUpdateScroll = ({
    routerProps: { location },
  }) => {
    window.scrollTo(...[0, 0])
    return false
  }