import { css } from 'styled-components';
import { theme } from '@styles/theme.styles';

export const Animations = css`

    .h_text-underline {
        position: relative;
        overflow: hidden;
        display: inline-flex;
        padding-bottom: 1px;
    
        &::before {
            content: '';
            transform: translateX(-101%);
            position: absolute;
            bottom: 1px;
            left: 0;
            width: 100%;
            height: 0px;
            border-bottom: 1px solid;
            transition: .25s ${theme.animations.default} transform;
        }

        &.h_underline-bold::before {
            border-bottom: 2px solid;
        }
    
        &:hover {
            text-decoration: none; // just incase default <a> tag is applied
            &::before {
                transform: translateX(0);      
            }
        }
    }
    
    a:hover, button:hover { // dont want underline to disappear on hovered links 
    
        .h_text-underline::before {
            transform: translateX(0);     
        }
    }


    @keyframes exitScreen {
        100% {
            transform: translate(-9999px);
        }
    }   
`