import styled, { css } from 'styled-components';
import { theme } from '@theme';
import { StyledModuleContainer } from '@styles/global-components/_containers';
import WrImage from '@commons/wr-image';
import WysiwygContent from '@commons/wysiwyg-content';
import { bodyFontSizing } from '@styles/global-components/_typography';
import ModuleTitle from '@commons/module-title';

const content_spacing = '25px'; // mobile first
const lg_content_spacing = '35px';

const sm_content_width = '60%'; // includes title
const md_content_width = '50%'; // includes title

const module_spacing = '40px';
const lg_module_spacing = '55px';

export const StyledHero = styled(StyledModuleContainer)`
    position: relative;
    z-index: 0;
    padding-top: 30px;
    padding-bottom: 30px;

    ${theme.mediaQuerys.smUp} {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledContentContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        display: flex;
        align-items: flex-end;
    }
`

export const StyledBottomContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${theme.mediaQuerys.smUp} {
        flex-direction: column;
        padding-left: 20px;
    }  

    ${theme.mediaQuerys.mdUp} {
        min-height: unset;
        padding: 0;
    }
`

export const StyledTextContentContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        flex: 1;
    }

    ${theme.mediaQuerys.mdUp} {
        width: ${md_content_width};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0;
    padding-bottom: 20px;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        text-align: left;
        padding-left: 20px;
        padding-bottom: 25px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-left: 0;
        padding-right: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-bottom: ${lg_content_spacing};
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    margin: 0 auto;
    padding-bottom: ${content_spacing};
    max-width: 200px;

    &.desktop {
        display: none;

        ${theme.mediaQuerys.smUp} {
            display: block;
        }
    }

    &.mobile {
        
        ${theme.mediaQuerys.smUp} {
            display: none;
        }
    }

    ${theme.mediaQuerys.smUp} {
        left: 0px;
        width: 50%;
        max-width: 380px;
        padding: 0;
        margin-bottom: -${module_spacing};
    }

    ${theme.mediaQuerys.mdUp} {
        position: relative !important;
        width: 45%;
        max-width: 550px;
        margin-bottom: -${module_spacing};
        padding-right: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: -${lg_module_spacing};
        max-width: 600px;
    }
`

export const StyledSubText = styled(WysiwygContent)`
    text-align: center;
    padding-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        text-align: left;
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        margin-left: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-bottom: ${lg_content_spacing};
    }
`

export const StyledLinksContainer = styled.div`
    

    ${theme.mediaQuerys.smUp} {
        margin-bottom: ${content_spacing};
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        margin-left: 0;
    }

    a {
        text-align: center;
        display: block;
        margin-bottom: 10px;
        ${bodyFontSizing}
        color: ${theme.colors.white};

        ${theme.mediaQuerys.smUp} {
            margin-bottom: 25px;
            text-align: left;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-bottom: ${lg_content_spacing};
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`