const CONFIG = require('../../node-lib/config')
const breakpoints = CONFIG.BREAKPOINTS;

const mediaQuerys = { // Mobile First Perspective always!! :)
    xsUp: `@media (min-width: ${breakpoints.xs})`,
    smUp: `@media (min-width: ${breakpoints.sm})`,
    mdUp: `@media (min-width: ${breakpoints.md})`,
    lgUp: `@media (min-width: ${breakpoints.lg})`,
    xlUp: `@media (min-width: ${breakpoints.xl})`
}

const defaultColors = {
    white: '#FFFFFF',
    black: `#000000`,
    lightBlue: `#C3F4ED`,
    fuchsia: `#AF1E87`,
    purple: `#935482`,
    yellow: `#D0F74B`,
    lightGrey: `#F4F4F4`,
    darkGrey: `#626565`,
    teal: `#148692`,
}

const colors = {
    navigationBackground: defaultColors.black,
    navigationText: defaultColors.white,
    navigationTextActive: defaultColors.lightBlue,
    white: defaultColors.white,
    black: defaultColors.black,
    headerText: defaultColors.black,
    headerTextOnBackground: defaultColors.white,
    bodyText: defaultColors.black,
    bodyTextOnBackground: defaultColors.white,

    // primary colors (not really using any rn)
    main1: defaultColors.lightBlue,
    main2: defaultColors.teal,
    main3: defaultColors.yellow,
    main4: defaultColors.fuchsia,
    main5: defaultColors.lightGrey,
    main6: defaultColors.darkGrey,

    // color values 
    ...defaultColors,

    // socials
    facebook: '#3b5998',
    twitter: '#00acee',
    pinterest:'#c8232c',
    youtube: '#a82400',
    instagram: '#517fa4'
}

const defaultFonts = {
    headerText: 'Montserrat',
    bodyText: `Segoe UI`,
    fallbacks: `Roboto,sans-serif,-apple-system,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue"`,
}

const fonts = {
    header: defaultFonts.headerText,
    body: defaultFonts.bodyText,
    button: defaultFonts.headerText,
    fallbacks: defaultFonts.fallbacks,
    base: `${defaultFonts.bodyText}, ${defaultFonts.fallbacks}`
}

const animations = {
    default: `cubic-bezier(0.645, 0.045, 0.355, 1)`
}

export const theme = {
    breakpoints: breakpoints,
    mediaQuerys: mediaQuerys,
    colors: colors,
    fonts: fonts,
    animations: animations
}
