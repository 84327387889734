import React from 'react'
import { StyledBlogDetails, StyledDescription , StyledButtonAndPointer, StyledBlogCard, StyledTitle } from './styles'

import WrButton from '@commons/wr-button'
import WrLink from '@commons/wr-link'
import WrPointer from '@commons/wr-pointer'
import WrImage from '@commons/wr-image'
import ShareCount from '../share-count'

export default function BlogCard({
    module,
    buttonText = '>>>> Read more',
}) {

    return (
        <StyledBlogCard className="container module-spacer-inner-md">
            <WrLink to={module.uri}>
                {module.title && 
                    <StyledTitle 
                        dangerouslySetInnerHTML={{ __html: module.title }}
                    />
                }

                <StyledBlogDetails className="details-section">
                    {module.author && 
                        <>
                            <img className="author-image" src={module.author.node.avatar.url} alt="A picture of the blog post author"/>
                            <span className="author" rel="author">
                                By {module.author.node.firstName || module.author.node.lastName ? 
                                    `${module.author.node.firstName} ${module.author.node.lastName}` :
                                    `Guest Author`
                                }
                                <ShareCount slug={module.uri} />
                            </span>
                        </>
                    }
                </StyledBlogDetails>

                {module.featuredImage && module.featuredImage.node ?               
                        <WrImage 
                            imageFile={module.featuredImage.node.imageFile}
                            src={module.featuredImage.node.mediaItemUrl}
                            alt={module.featuredImage.node.altText}
                            className={"fluid featured-image"}
                        />             
                : null}

                {module.seo.metaDesc && 
                    <StyledDescription
                        dangerouslySetInnerHTML={{ __html: module.seo.metaDesc }}
                    />
                }

                <StyledButtonAndPointer >
                    <WrPointer  
                        className="blog-card-pointer" 
                        direction="right"
                    />
                    <WrButton 
                        buttonWrapperClass="blog-card-btn-wrapper title-font"
                        buttonClass="blog-card-btn"
                    >
                        {buttonText}
                    </WrButton>
                </StyledButtonAndPointer>
            </WrLink>
        </StyledBlogCard>
    )
}
