// ********************************************************
// ********************************************************
// **
// **  Reusable Blog Hero Styles
// **  -- styles used in multiple blog hero components
// **
// ********************************************************
// ********************************************************

import styled, { css } from 'styled-components';
import { theme } from '@theme';

import ModuleTitle from '@commons/module-title';

export const StyledTitle = styled(ModuleTitle)`
    margin: 0;
    font-weight: 800;
    text-align: center; 
    max-width: 800px;

    ${theme.mediaQuerys.smUp} {
        padding: 0 20px;
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: 920px;
        padding: 0 40px;
    }
`

export const StyledAuthorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 20px 0;
`

export const StyledAuthorImage = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.25);

    @supports (filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))) {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        box-shadow: none;
    }

    ${theme.mediaQuerys.smUp} {
        width: 35px;
        height: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: 40px;
        height: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        width: 45px;
        height: 45px;
    }
`

export const StyledAuthorName = styled.span`
    margin-left: 15px;
    font-size: 1.6rem;
    font-weight: 300;
    font-style: italic;
    /* color: ${props => props.textColor || theme.colors.white}; */

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.8rem;
    }
`