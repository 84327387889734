import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import ModuleTitle from '@commons/module-title';

const content_spacing = '20px'; // mobile first
const lg_text_offset = '15px';

const sm_content_width = '60%'; // excludes title
const md_content_width = '60%'; // includes title
const lg_content_width = '60%'; // includes title

const module_spacing = '40px';
const lg_module_spacing = '55px';

export const StyledHero = styled(StyledModuleContainer)`
    padding-top: ${module_spacing};
    padding-bottom: ${module_spacing};

    ${theme.mediaQuerys.lgUp} {
        padding-top: ${lg_module_spacing};
        padding-bottom: ${lg_module_spacing};
    }
`

export const StyledHeroContentContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        min-height: 460px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: ${md_content_width};
        min-height: 415px;
        margin-left: auto;
    }

    ${theme.mediaQuerys.lgUp} {
        width: ${lg_content_width};
        min-height: 440px;
    }
`

export const StyledSubTitleTopAndImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;

    ${theme.mediaQuerys.smUp} {
        position: static;
    }
`

export const StyledFeaturedImage = styled.div`
    width: 60%;
    padding-left: 10px;
    padding-right: 5px;
    max-width: 200px;
    margin: 0 auto;

    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    ${theme.mediaQuerys.smUp} {
        position: absolute;
        width: 37%;
        max-width:305px;
        margin: 0;
        left: 15px;
        bottom: -${module_spacing};
    }

    ${theme.mediaQuerys.mdUp} {
        max-width: 400px;
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: 425px;
        left: 25px;
        bottom: -${lg_module_spacing};
    }
`

export const StyledTitle = styled(ModuleTitle)`
    font-size: 2.2rem;
    line-height: 34px;
    font-weight: 900;
    margin: 0;
    padding-bottom: ${content_spacing};
    width: 100%;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        text-align: left;
        font-size: 3rem;
        line-height: 42px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* font-size: 3.5rem;
        line-height: 48px; */
        font-size: 3rem;
        line-height: 42px;
        /* font-size: 3.5rem;
        line-height: 43px; */
        padding-right: ${lg_text_offset};
    }
`

export const StyledSubTitleTop = styled.div`
    font-size: 2.2rem;
    line-height: 29px;
    font-style: italic;
    padding-bottom: ${content_spacing};
    margin: 0;
    flex: 1;

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-bottom: ${content_spacing};
        padding: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
    }

    p {
        margin: 0;
    }
`

export const StyledSubTitleBottom = styled.div`
    font-size: 1.5rem;
    line-height: 19px;
    text-align: center;
    padding: 0 20px;
    margin: 0 0 ${content_spacing} 0;

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-left: auto;
        margin-bottom: ${content_spacing};
        text-align: left;
        padding: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        margin-left: 0;
        font-size: 1.8rem;
        line-height: 22px;
        margin-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-right: ${lg_text_offset};
    }

    p {
        margin: 0;
    }
`

export const StyledTextWithBackground = styled.div`
    background-color: ${props => props.$backgroundColor || '#117782'};
    color: ${props => props.$textColor || '#117782'};
    margin-bottom: ${content_spacing};
    padding: 15px 25px;
    border-radius: 25px;
    font-size: 1.8rem;
    line-height: 25px;
    font-weight: 700;
    text-align:center;
    
    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-left: auto;
        border-radius: 0;
        padding: 10px 15px;
        text-align: left;
    }

    ${theme.mediaQuerys.mdUp} {
        width: auto;
        margin-left: 0;
        font-size: 1.8rem;
        line-height: 22px;
        margin-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-right: ${lg_text_offset};
    }

    p {
        margin: 0;
    }
`

export const StyledButtonsContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        width: ${sm_content_width};
        margin-left: auto;
    }

    ${theme.mediaQuerys.mdUp} {
        padding-top: 10px;
        width: auto;
        margin-left: 0;
        display: flex;
    }

    .course-button {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0px;

            ${theme.mediaQuerys.mdUp} {
                margin-right: 0;
            }
        }

        ${theme.mediaQuerys.mdUp} {
            margin: 0;
            margin-right: 20px;
        }
    }
`

export const StyledButton = styled(WrButton)`
    padding: 10px 12px;
    min-height: 32px;
    font-size: 1.8rem;
`

export const StyledPointer = styled(WrPointer)`
    display: block;
    position: absolute;
    top: 0;
    right: -15px;
    height: 105px;
    transform: rotate(-135deg) scaleX(-1);

    ${theme.mediaQuerys.xsUp} {
        transform: rotate(-125deg) scaleX(-1);
        right: -20px;
    }

    ${theme.mediaQuerys.smUp} {
        height: 90px;
    }
`