import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledBlogSidebar = styled.aside`
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% + 25px));
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 1265px;
    opacity: 0;
    transition: opacity .25s ease;

    ${theme.mediaQuerys.lgUp} {
        display: block;
    }

    ${props => props.$isVisible && css`
        opacity: 1;
    `}

    .inner-sidebar-container {
        max-width: 180px;
        position: sticky;
        top: 100px;
        left: 25px;
    }

    .share-count-wrapper {
        font-size: 1.4rem;
        font-weight: 600;
        max-width: 42px;
        text-transform: lowercase;
        padding: 8px 0;
        text-align: center;
    }

    .sidebar-social-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        border-bottom: 1px solid #C4C4C4;
        width: 80%;
        margin-left: auto;
        padding-right: 40px;
        padding-bottom: 20px;
        margin-bottom: 25px;
    }

    .social-cta {
        padding: 10px;

        &.facebook {
            color: ${theme.colors.facebook};
        }

        &.twitter {
            color: ${theme.colors.twitter};
        }
    }

    .social-icon {
        display: block;
        height: 20px;
        width: auto;
    }

    .blog-sidebar-copy {
        font-size: 1.6rem;
        font-weight: 900;
        font-family: ${theme.fonts.header};
        color: #5F5A5E;
        text-align: right;
        margin-bottom: 25px;
    }

    .blog-sidebar-btn {

    }

    .blog-sidebar-btn button, .blog-sidebar-btn a {
        font-size: 1.4rem;
        padding-top: 8px;
        padding-bottom: 8px;
        min-height: 30px;
        font-style: italic;
        /* height: 30px; */
    }
`