
import { H1, H2, H3, H4, H5, H6, Dl, Ol, Ul, P, Table, Img } from '@styles/global-components/_typography';
import WrLink from '@commons/wr-link';
import { graphql } from 'gatsby';

const TypographyFields = {
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    dl: Dl,
    ol: Ol,
    ul: Ul,
    table: Table,
    a: WrLink,
    img: Img
}

const AllBlockTags = {
    p: 1,
    h1: 1,
    h2: 1,
    h3: 1,
    h4: 1,
    h5: 1,
    h6: 1,
    dl: 1,
    ol: 1,
    ul: 1,
    li: 1,
    table: 1,
    // img: 1
}

export const getBlockTag = (name) => AllBlockTags[name];
export const getTypographyField = (name) => TypographyFields[name]; 