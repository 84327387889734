import React from 'react'

import Modules from '@modules';

export default function CoursesTemplate({
    pageContext: {
      uri,
      seo,
      id,
      pageData = {
        modularLayout: {},
      },
    }
  }) {

    const { modules = [], __typename } = pageData.modularLayout;

    return (
        <main>
            <Modules 
                modules={modules}
                typeName={__typename}
                id={id}
            />   
        </main>
    )
}
